import React from 'react'
import './med-ed-bio-data.scss'
import MedEdListItem from './MedEdListItem.jsx'

export default class MedEdBioData extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      detailsOpen: false,
      currentElement: null
    }
    this.setCurrentElement = this.setCurrentElement.bind(this)
    this.listItems = this.listItems.bind(this)
  }

  setCurrentElement (element) {
    let passedDetailsContainer = document.getElementById(element)
    let currentDetailsContainer = document.getElementById(this.state.currentElement)
    let passedListItem = document.getElementById('li-' + element)
    let currentListItem = document.getElementById('li-' + this.state.currentElement)
    let mainContainerSelector = document.querySelector("[id='" + this.props.containerId + "']" + ' .bio-data-list')
    let listTop = mainContainerSelector && mainContainerSelector.getBoundingClientRect().top
    let itemTop = passedListItem && passedListItem.getBoundingClientRect().bottom

    let elementValue
    if (element === this.state.currentElement) {
      elementValue = null
      passedDetailsContainer.style.display = 'none'
    } else if (this.state.currentElement === null) {
      elementValue = element
      passedDetailsContainer.style.display = 'block'
    } else {
      elementValue = element
      currentDetailsContainer.style.display = 'none'
      currentListItem.style.top = '0px'
      currentListItem.style.marginBottom = '0px'
      passedDetailsContainer.style.display = 'block'
      passedDetailsContainer.style.top = (itemTop - listTop) + 'px'
    }

    this.setState({
      currentElement: elementValue
    })
  }

  listItems () {
    return this.props.bioData.map((item, index) => {
      return <MedEdListItem key={index} containerId={this.props.containerId} item={item} currentElement={this.state.currentElement} setCurrentElement={this.setCurrentElement} />
    })
  }

  render () {
    return (
      <div className='med-ed-bio-data-container'>
        {this.props.DisplayHeader && <h2 className='meded-filter--heading'>{this.props.YearOfStudy} {this.props.Type}</h2>}
        <ul className='bio-data-list columns row align-top'>
          {this.listItems()}
        </ul>
      </div>
    )
  }
}
