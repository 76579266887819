import { connect } from 'react-redux'
import {getFilteredItemCount, getFilteredItems, getFilterCount} from './selectors/insurance-selectors.js'
import InsuranceSearch from './InsuranceSearch.jsx'
import { getURLParameters } from '../../shared/utility-functions/index.js'

const mapStateToProps = (state, ownProps) => {
  return ({
    headline: ownProps.headline,
    searchItems: getFilteredItems(state),
    searchItemCount: getFilteredItemCount(state),
    dataLoaded: state.dataLoaded,
    searched: state.searched,
    urlParameters: getURLParameters(),
    sort: state.sortOrder,
    filters: state.filters,
    filterCount: getFilterCount(state),
    fetchCallFailed: state.callFailed
  })
}

const mapDispatchToProps = (dispatch) => ({
  runSearch: (params) => dispatch({
    type: 'GET_INSURANCE',
    params: params
  }),
  setSort: (value) => dispatch({
    type: 'SET_SORT',
    value
  }),
  setFilters: (value) => dispatch({
    type: 'SET_FILTERS',
    value
  }),
  resetFilters: () => dispatch({
    type: 'RESET_FILTERS'
  }),
  setPageNumber: (number) => dispatch({
    type: 'SET_CURRENT_PAGE',
    number
  })
})

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    initPage: () => {
      dispatchProps.runSearch()
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InsuranceSearch)
