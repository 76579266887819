import React, { useState, useEffect, useRef, Fragment } from 'react'
import Chip from '../../../shared/components/Chip'
import GroupChip from './GroupChip/GroupChip.jsx'
import './uc-reason-for-visit-modal.scss'
import { UCReasonForVisitDisclaimer, UCReasonForVisitQuestion, UCReasonForVisitInPerson, scheduleQuestionEvent, clickToCallEvent } from '../../../shared/utility-functions/googleTagManager'
import SVGInline from 'react-svg-inline'
import exclamationIcon from '../../../shared/assets/notice.svg'
import { FlowTypes } from '../../../shared/types/FlowType'
import { clickToCallTransformer } from '../../../shared/data-mappers/click-to-call-transformer'
import LoadingElement from '../../../shared/components/LoadingElement'
import { isNullOrWhitespace } from '../../../shared/utility-functions/stringUtils'
import { SEARCH_UC_REASON_FOR_VISIT } from '../../../shared/constants/api-helpers.js'
import { fetchQuestionnaireData } from '../FetchQuestionnaireData'

const UCReasonForVisitModal = ({ handleSubmission, setEligibility, setVideoVisitEligibility, setReasonForVisit }) => {
  const [step, setStep] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
//////
  const isMounted = useRef(false)
  const [conditionGroupsErrorMessage, setConditionGroupsErrorMessage] = useState('')
  const [isConditionGroupsFetchSuccessful, setIsConditionGroupsFetchSuccessful] = useState(false)
  const [conditionGroups, setConditionGroups] = useState([])
  const [followUpQuestions, setFollowUpQuestions] = useState([])
  const [currentFollowUpQuestion, setCurrentFollowUpQuestion] = useState({})
  const ConditionType = {
    CONDITION: 'Condition',
    CONDITION_GROUP: 'ConditionGroup'
  }
  const FollowUpType = {
    DISCLAIMER: 3,
    QUESTION: 4
  }
  const FollowQuestionResponse = {
    OK: 'ok',
    YES: 'yes',
    NO: 'no'
  }
//////
  const [reasonForVisitGroup, setReasonForVisitGroup] = useState({})
  const [reasonForVisitConditions, setReasonForVisitConditions] = useState([])

  useEffect(() => {
    if(isMounted.current){
      getFollowUpQuestion()
    }
    else {
        isMounted.current = true
    }
  }, [followUpQuestions]);

  useEffect(() => {
    fetchQuestionnaireData(SEARCH_UC_REASON_FOR_VISIT, {'Search': ''}).then(response => {
      setConditionGroupsErrorMessage(response.ErrorMessage)
      setIsConditionGroupsFetchSuccessful(response.Success)
      setConditionGroups(response.Groups)
    })
  }, []);

  const getFollowUpQuestion = () => {
    const currentQuestion = followUpQuestions.shift()

    if(currentQuestion){
        setCurrentFollowUpQuestion(currentQuestion)
        setStep(currentQuestion.Type)
        return
    }
    isEligibleForVideoVisit()
  }

  const handleGroupChipClick = (groupId) => {
    const group = conditionGroups.find(i => i.ID === groupId)
    if (group) {
      setReasonForVisitGroup(group)
    } else {
      setReasonForVisitGroup({})
    }
  }

  const addReasonForVisitCondition = (condition) => {
    setReasonForVisitConditions([...reasonForVisitConditions, condition])
  }

  const removeReasonForVisitCondition = (condition) => {
    setReasonForVisitConditions(reasonForVisitConditions.filter(reasonForVisitCondition => reasonForVisitCondition !== condition))
  }

  const handleFollowUpResponse = (title, response) => {
    if (response === FollowQuestionResponse.YES) { // analytics
      UCReasonForVisitDisclaimer(title)
    } else {
      UCReasonForVisitQuestion(title, response)
    }

    getFollowUpQuestion()
  }

  const handleLocationClick = () => {
    UCReasonForVisitInPerson() // analytics
    setVideoVisitEligibility(false)
    setEligibility(false)
    setReasonForVisit(reasonForVisitGroup)
    handleSubmission()
  }

  const isEligibleForVideoVisit = () => {
    const isEligible = reasonForVisitGroup.Conditions && reasonForVisitGroup.Conditions.filter(item => !item.VideoVisit).length === 0
    setVideoVisitEligibility(isEligible)
    setEligibility(isEligible)
    setReasonForVisit(reasonForVisitGroup)

    if (isEligible) {
      handleSubmission()
      return
    }

    if (!isEligible) {
      setStep(5)
    }
  }

  const doScheduleQuestionEvent = (conditionType) => {
    const dataLayerObject = {
      flow: 'uc',
      scheduleQuestionNumber: undefined,
      scheduleQuestion: `Reason For Visit ${conditionType === ConditionType.CONDITION ? ' Conditions' : ''}`,
      scheduleQuestionAnswer: 'Continue'
    }

    scheduleQuestionEvent(dataLayerObject)
  }

  const handleGroupClick = () => {
    doScheduleQuestionEvent(ConditionType.CONDITION_GROUP)

    if(reasonForVisitGroup.Conditions.length > 1 || (reasonForVisitGroup.Conditions.length === 1 && !isNullOrWhitespace(reasonForVisitGroup.Conditions[0].DisplayName))){
      setStep(2)
      return
    }

    const followUpQuestions = buildFollowUpQuestions(ConditionType.CONDITION_GROUP)

    if (followUpQuestions && followUpQuestions.length > 0) {
      setFollowUpQuestions(followUpQuestions)
      return
    }

    isEligibleForVideoVisit()
  }

  const buildFollowUpQuestions = (conditionType) => {
    if(conditionType === ConditionType.CONDITION_GROUP){
      return reasonForVisitGroup.Conditions
        .filter(item => !isNullOrWhitespace(item.Statement))
        .map(item => ({
        Type: item.StatementType ? FollowUpType[item.StatementType.toUpperCase] : FollowUpType.DISCLAIMER,
        Title: reasonForVisitGroup.DisplayName,
        Text: item.Statement
        }))
    }
    else if (conditionType === ConditionType.CONDITION) {
      return reasonForVisitGroup.Conditions
        .filter(item => reasonForVisitConditions.includes(item.DisplayName) && !isNullOrWhitespace(item.Statement))
        .map(item => ({
        Type: item.StatementType ? FollowUpType[item.StatementType.toUpperCase()] : FollowUpType.DISCLAIMER,
        Title: item.DisplayName,
        Text: item.Statement
        }))
    }
    else {
      return null
    }
   }

  const handleConditionsClick = () => {
    doScheduleQuestionEvent(ConditionType.CONDITION)

    const followUpQuestions = buildFollowUpQuestions(ConditionType.CONDITION)

    if (followUpQuestions && followUpQuestions.length > 0) {
      setFollowUpQuestions(followUpQuestions)
      return
    }

    isEligibleForVideoVisit()
  }

  const handleBackClick = () => {
    setStep(1)
    setSearchTerm('')
    setReasonForVisitGroup({})
    setReasonForVisitConditions([])
    setFollowUpQuestions([])    
    setCurrentFollowUpQuestion({})
  }

  const clickToCall = () => {
    clickToCallEvent(clickToCallTransformer(FlowTypes.E911))
  }

  const isEmptyObject = (obj) => {
    Object.keys(obj).length === 0 && obj.constructor === Object
  }

  const loading = () => {
    <div>
        <LoadingElement />
    </div>
  }

  const renderSwitch = () => {
    switch (step) {
      case 1: // Select Group
        return <div className='uc-reason-for-visit-modal'>
          {!isConditionGroupsFetchSuccessful && loading}
          {isConditionGroupsFetchSuccessful && 
            <Fragment>
                <p className='title'>Reason for Urgent Care Visit</p>
                <p className='explanation'>Select one.</p>
                <input type='text' placeholder='Search for a condition...' className='condition-search-input' onChange={event => { setSearchTerm(event.target.value) }} />
                <div className='reasons'>
                {
                    conditionGroups.filter((item) => {
                    if (searchTerm === '') {
                        return item
                    } else if (item.DisplayName.toLowerCase().includes(searchTerm.toLowerCase()) || item.Conditions.filter(condition => condition.DisplayName.toLowerCase().includes(searchTerm.toLowerCase())).length > 0) {
                        return item
                        }
                    }).map(item => <GroupChip key={item.DisplayName} conditionGroup={item} clickHandler={handleGroupChipClick} reasonForVisitGroup={reasonForVisitGroup} />)
                }
                </div>
                <div className='warning'>
                    <a onclick={clickToCall}>Call 911</a> if you are experiencing chest pain, shortness of breath, or a life threatening emergency.
                </div>
                <div className='btn-container'>
                    <button className='btn btn-primary continue-button' disabled={isEmptyObject(reasonForVisitGroup)} onClick={handleGroupClick}>Continue</button>
                </div>
            </Fragment>
          }
        </div>
      case 2: // Select Conditions
        return <div className='uc-reason-for-visit-modal'>
          <p className='title'>Reason for Urgent Care Visit</p>
          <p className='explanation'>Select all that apply.</p>
          <div className='reasons'>
            {
              reasonForVisitGroup.Conditions.map(item => <Chip key={item.DisplayName} text={item.DisplayName} selectHandler={addReasonForVisitCondition} deselectHandler={removeReasonForVisitCondition} />)
            }
          </div>
          <div className='warning'>
            <a onclick={clickToCall}>Call 911</a> if you are experiencing chest pain, shortness of breath, or a life threatening emergency.
          </div>
          <div className='btn-container'>
            <button className='btn btn-primary go-back-button' onClick={handleBackClick}>Go Back</button>
            <button className='btn btn-primary continue-button' disabled={reasonForVisitConditions.length < 1} onClick={handleConditionsClick}>Continue</button>
          </div>
        </div>
      case 3: // Follow up (Disclaimer)
        return <div className='uc-reason-for-visit-modal'>
          <p className='title'>{currentFollowUpQuestion.Title}</p>
          <div className='warning'>{currentFollowUpQuestion.Text}</div>
          <div className='btn-container'>
            <button className='btn btn-primary continue-button' onClick={() => { handleFollowUpResponse(currentFollowUpQuestion.Title, FollowQuestionResponse.OK) }}>OK</button>
          </div>
        </div>
      case 4: // Follow up (Question)
        return <div className='uc-reason-for-visit-modal'>
          <p className='title'>{currentFollowUpQuestion.Title}</p>
          <div className='warning'>{currentFollowUpQuestion.Text}</div>
          <div className='btn-container'>
            <button className='btn btn-primary continue-button' onClick={() => { handleFollowUpResponse(currentFollowUpQuestion.Title, FollowQuestionResponse.YES) }}>Yes</button>
            <button className='btn btn-primary continue-button' onClick={() => { handleFollowUpResponse(currentFollowUpQuestion.Title, FollowQuestionResponse.NO) }}>No</button>
          </div>
        </div>
      case 5: // In Person Visit
        return <div className='uc-reason-for-visit-modal'>
          <SVGInline svg={exclamationIcon} />
          <p className='title in-person'>Video visit not available for your condition</p>
          <div className='warning in-person'>One or more of your reasons for a visit cannot be treated via video. We recommend visiting an urgent care location for the best care possible.</div>
          <div className='btn-container'>
            <button className='btn btn-primary continue-button' onClick={handleLocationClick}>I UNDERSTAND</button>
          </div>
        </div>
      default:
        return <div />
    }
  }

  return (renderSwitch())
}

export default UCReasonForVisitModal
