import React from 'react'

export default class CategoryQuestion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      index: this.props.index + 1,
      shape: this.props.question ? this.props.question.shape : 'circle',
      type: this.props.question ? this.props.question.type : 'text',
      clicked: null
    }
    this.clickEvent = this.clickEvent.bind(this)
  }

  clickEvent (i, answer) {
    this.setState({ clicked: answer.letter })
    this.props.clickHandler(i, answer.category)
  }

  render () {
    return <div className='epi-block category-question full'>
      <div className={`question-container category ${this.props.question.image ? 'has-bg-image' : ''}`} style={`background-image: url('${this.props.question.image}');`}>
        <div className='question-number'>QUESTION {this.state.index} OF <span className='questionCount'>{this.props.total}</span></div>
        <div className='question-title' dangerouslySetInnerHTML={{__html: this.props.question.text}} />
        <div className='clearfix'>&nbsp;</div>
        <div className={`answers-container ${this.state.type.toLowerCase() === 'text' ? 'text' : 'number'}`}>
          {
            this.props.question && this.props.question.answers && this.props.question.answers.length > 0 &&
            this.props.question.answers.map((answer, index) => {
              return <div className={`answer ${this.state.clicked === answer.letter ? 'clicked correct' : ''}`} key={index} onClick={() => { this.clickEvent(this.props.index, answer) }}>
                <div className='label'>
                  <div className={`${this.state.type.toLowerCase() === 'image' ? 'image' : ''} border-${this.state.shape.toLowerCase() === 'square' ? 'square' : 'circle'}`} style={`background-image:url('${answer.image}');`}>
                    {this.state.type.toLowerCase() !== 'image' && answer.copy}
                  </div>
                  {answer.imageCaption && <div className='image-caption'>{answer.imageCaption}</div>}
                  <div className='option-letter'>{answer.letter}</div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  }
}
