import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import searchSaga from './sagas/insurance-search-saga'
import insuranceReducer from './state/insurance-reducer'
import InsuranceSearchConnector from './InsuranceSearchConnector.jsx'

export default class Root extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.store = createStore(insuranceReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
    sagaMiddleware.run(searchSaga)
  }

  render () {
    return (
      <Provider store={this.store}>
        <InsuranceSearchConnector />
      </Provider>
    )
  }
}
