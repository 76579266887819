import React from 'react'
import URLS from '../../shared/constants/urls'
import urgentCareIcon from '../../shared/assets/urgent-care-icon.svg'
import SVGInline from 'react-svg-inline'

export default class OnMyWayButton extends React.Component {
  render () {
    return <a href={`${URLS.gatewayPageUrl}/urgent-care-scheduling/appointment-selection`} className={`get-care-now-btn ed-action ${this.props.cms ? 'cms-action' : ''}`} target='_blank'>
      <span className='action-label'><SVGInline svg={urgentCareIcon} /> SCHEDULE NOW</span>
    </a>
  }
}
