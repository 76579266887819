import React from 'react'
import SVGInline from 'react-svg-inline'
import './notice-modal.scss'

export default class NoticeModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.props.notice
    this.handleOkButtonClick = this.handleOkButtonClick.bind(this);
  }

  handleOkButtonClick() {
    this.props.onStepChange(3);
  }

  render () {
    return (
      <div className='notice-modal'>
        {this.state.icon && <SVGInline svg={this.state.icon} className='notice-icon' />}
        <div className='modal-text'>
          <div className='title'>Notice</div>
          <div className='subheader'>Once you turn 40 years old, you become eligible for an annual mammogram. Schedule after your birthday.</div>>
        </div>
        <button className="close" onClick={this.handleOkButtonClick}>View Times</button>
      </div>
    )
  }
}
