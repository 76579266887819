import React from 'react'
import './landing-page.scss'
import SVGInline from 'react-svg-inline'
import LocationInput from '../Search/components/SearchBar/LocationInput/LocationInput.jsx'
import LookingForConnector from '../Search/components/SearchBar/LookingFor/LookingForConnector.jsx'
import LookingForLocationsConnector from '../Search/components/SearchBar/LookingFor/LookingForLocationsConnector.jsx'
import LookingForServicesConnector from '../Search/components/SearchBar/LookingFor/LookingForServicesConnector.jsx'
import LookingForClassesConnector from '../Search/components/SearchBar/LookingFor/LookingForClassesConnector.jsx'
import LocationWithin from '../Search/components/SearchBar/LocationWithin/LocationWithin.jsx'
import StethoscopeIcon from '../shared/assets/stethoscope-fad.svg'
import InfoIcon from '../shared/assets/info-fad.svg'
import InnerHTMLHelper from '../shared/components/InnerHTMLHelper/InnerHTMLHelper.jsx'
import {getParameterByName} from '../shared/utility-functions/searchParams'

export default class LandingPage extends React.Component {
  constructor (props) {
    super(props)
    this.searchCalls = 0
    this.props.initializePage()

    this.setLocation = this.setLocation.bind(this)
    this.setLookingFor = this.setLookingFor.bind(this)
    this.setWithin = this.setWithin.bind(this)
    this.renderLookingFor = this.renderLookingFor.bind(this)
    this.enterSubmit = this.enterSubmit.bind(this)
    this.getType = this.getType.bind(this)

    this.state = {
      address: '',
      latitude: '',
      longitude: '',
      within: '25',
      lookingFor: '',
      typeLabel: this.props.title ? this.props.title : this.getType()
    }
  }

  componentDidMount () {
    this.setState({
      lookingFor: getParameterByName('q') || '',
      address: getParameterByName('locq') || '',
      latitude: getParameterByName('lat') || '',
      longitude: getParameterByName('lng') || '',
      distance: getParameterByName('dist') || '25',
      type: getParameterByName('type') || ''
    })
  }

  setLookingFor (lookingFor) {
    this.setState({ lookingFor: lookingFor })
  }

  setLocation (value, lat, lng) {
    if (!value) {
      lat = ''
      lng = ''
    }
    let modifiedValue = value
    if (value) modifiedValue = value.replace(", USA", "")
    this.setState({ address: modifiedValue, latitude: lat, longitude: lng })
  }

  setWithin (value) {
    this.setState({ within: parseInt(value) })
  }

  getType () {
    switch (this.props.type) {
      case 'doctors':
        return 'Doctor'
      case 'locations':
        return 'Location'
      case 'services':
        return 'Service'
      default:
        break
    }
  }

  applyClicked () {
    if (this.state.address && this.state.address !== '' && (!this.state.latitude || !this.state.longitude)) {
      this.searchCalls += 1
      if (this.searchCalls < 20) {
        setTimeout(() => {
          this.applyClicked()
        }, 100)
        return
      } else {
        this.searchCalls = 0
      }
    } else {
      this.searchCalls = 0
    }

    let urlbase = window.location.pathname[window.location.pathname.length - 1] === '/' ? window.location.pathname.substr(0, window.location.pathname.length - 1) : window.location.pathname

    window.location.href = `http://${window.location.hostname}${urlbase}/results?${this.state.lookingFor ? 'q=' + encodeURIComponent(this.state.lookingFor) + '&' : ''}${this.state.address ? 'locq=' + this.state.address + '&' : ''}${this.state.latitude ? 'lat=' + this.state.latitude + '&' : ''}${this.state.longitude ? 'lng=' + this.state.longitude + '&' : ''}${this.state.within && (this.state.address || this.state.latitude || this.state.longitude) ? 'dist=' + this.state.within + '&' : ''}${this.props.type ? 'type=' + this.props.type : ''}`

  }

  validateEnter () {
    if (this.props.type !== 'services') {
      if (this.state.lookingFor) {
        return true
      } else if (this.state.address) {
        return true
      }
    } else {
      if (this.state.lookingFor) {
        return true
      }
    }
    return false
  }

  enterSubmit (event) {
    let enableSearch = false
    enableSearch = this.validateEnter()

    if (enableSearch) {
      if (event.keyCode === 13) {
        this.applyClicked()
      }
    }
  }

  renderLookingFor () {
    if (this.props.type === 'doctors') {
      return <LookingForConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
    } else if (this.props.type === 'locations') {
      return <LookingForLocationsConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
    } else if (this.props.type === 'services') {
      return <LookingForServicesConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
    } else if (this.props.type === 'classes') {
      return <LookingForClassesConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
    }
  }

  headerFontSize () {
    let headerSize = 'inherit'
    if (this.props.type === 'doctors') {
      headerSize = '39px'
    } else if (this.props.type === 'locations') {
      headerSize = '38px'
    } else if (this.props.type === 'services') {
      headerSize = '48px'
    }
    return headerSize
  }

  render () {
    let labelStyle = { fontSize: this.headerFontSize() }

    if (this.props.type !== 'classes') {
      return (
        <div className='landing-page-container'>
          <div className='find-search-label'>
            <div className='landing-page-find-a-title'>Find A <SVGInline className='stethoscope' svg={StethoscopeIcon} /></div>
            <div className='landing-page-title' style={labelStyle}>
              {this.state.typeLabel}
            </div>
          </div>

          <div className='mobile-find-label'>
            <div>Find A <SVGInline className='stethoscope' svg={StethoscopeIcon} /></div>
            <div className='doctor-text'>{this.state.typeLabel}</div>
          </div>

          <div className='input-row-container'>
            <div className='input-row'>
              <div className='input-label-container'>
                {this.renderLookingFor()}
              </div>
              {
                this.props.type !== 'services' &&
                <div className='tablet-within'>
                  <div className='input-label-container'>
                    <LocationInput address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} enterSubmit={this.enterSubmit} />
                  </div>
                  {
                  this.props.type !== 'doctors' &&
                    <div className='input-label-container within-container'>
                      <LocationWithin address={this.state.address} setWithin={this.setWithin} within={this.state.within} />
                    </div>
                  }
                </div>

              }
              <div className='search-button-container'>
                <button className='btn apply-button fad-search-button desktop' onClick={() => this.applyClicked()} id='LANDING_PAGE_SEARCH_BUTTON'>Search</button>
              </div>
            </div>
            <div className='info-box'>
              <SVGInline className='info-icon' svg={InfoIcon} />
              {
                this.props.displayMessage &&
                  <InnerHTMLHelper tagName='div' html={this.props.displayMessage} className='find-block--schedule-text' />
              }
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='classes-landing-page-container'>
          <h1>{this.props.title}</h1>
          <div className='inputContainer'>
            <LookingForClassesConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
            <div className='class-search-button-container'>
              <button className='btn apply-button fad-search-button desktop' onClick={() => this.applyClicked()} id='LANDING_PAGE_SEARCH_BUTTON'>Search</button>
            </div>
          </div>
          {
            this.props.categories && this.props.categories.length > 0 &&
            <div className='categories-container classes-categories-container'>
              <h2 className='category-header'>Class Categories</h2>
              <hr />
              <div className='items-container'>
                {
                  this.props.categories.map((item, index) => {
                    return <div className='item'><a href={`${window.location.href}${window.location.href[window.location.href.length - 1] === '/' ? '' : '/'}results?q=${item.value}`} key={index}>{item.label}</a></div>
                  })
                }
              </div>
            </div>
          }
        </div>
      )
    }
  }
}
