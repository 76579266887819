import React from 'react'
import './med-ed-bio-data.scss'
import SVGInline from 'react-svg-inline'
import sanitizeHtml from 'sanitize-html-react'
import CloseIcon from '../../shared/assets/x.svg'

export default class MedEdListItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      topOffsetValue: '0px',
      itemMarginBottom: '0px'
    }
    this.toggleDetails = this.toggleDetails.bind(this)
    this.createDetailRow = this.createDetailRow.bind(this)
  }

  toggleDetails (e) {
    this.props.setCurrentElement(this.props.item.Id)

    let listTop = document.querySelector("[id='" + this.props.containerId + "']" + ' .bio-data-list').getBoundingClientRect().top
    let itemTop = this.listItem.getBoundingClientRect().bottom
    this.detailContainer.style.display = (this.props.currentElement !== this.props.item.Id || this.props.currentElement === null) ? 'block' : 'none'
    let detailHeight = this.detailContainer.offsetHeight

    this.setState({
      topOffsetValue: (itemTop - listTop) + 'px',
      itemMarginBottom: detailHeight + 'px'
    })
  }

  createDetailRow (label, value) {
    let returnItem = [
      <div className='small-4 columns meded-filter-item--content-label'>
        <p className={value + '-label-created'} dangerouslySetInnerHTML={{__html: sanitizeHtml(label)}} />
      </div>,
      <div className='small-8 columns meded-filter-item--content-data'>
        <p className={value + '-value-created'} dangerouslySetInnerHTML={{__html: sanitizeHtml(value)}} />
      </div>
    ]
    return returnItem
  }

  render () {
    let detailStyle, toggleMargin, detailOffset, listStyle

    if (this.props.currentElement !== this.props.item.Id || this.props.currentElement === null) {
      listStyle = 'bio-data-item large-4 medium-6 small-12'
      detailStyle = 'meded-filter-item--content small-12 columns small-collapse medium-uncollapse'
      toggleMargin = {}
      detailOffset = {}
    } else {
      listStyle = 'bio-data-item large-4 medium-6 small-12 active'
      detailStyle = 'meded-filter-item--content small-12 columns small-collapse medium-uncollapse active'
      toggleMargin = {'margin-bottom': this.state.itemMarginBottom}
      detailOffset = {'position': 'absolute', 'top': this.state.topOffsetValue, 'left': '0px'}
    }

    return (
      <li id={'li-' + this.props.item.Id} ref={(listItem) => { this.listItem = listItem }} className={listStyle} onClick={(e) => this.toggleDetails(e)} style={toggleMargin}>

        <div className='bio-image'><img src={this.props.item.ImageURL} alt={'Portrait of ' + this.props.item.Name} /></div>

        <div className='meded-filter-item--heading columns'>
          <span className='bio-name-title'>{this.props.item.Name}</span>
          {this.props.item.ProgramTitle && <p className='bio-program-title'>{this.props.item.ProgramTitle}</p>}
          {this.props.item.Title && <p className='bio-title'>{this.props.item.Title}</p>}
          {this.props.item.YearLabel && <p className='bio-year-label'>{this.props.item.YearLabel}</p>}
        </div>

        <div id={this.props.item.Id} ref={(details) => { this.detailContainer = details }} className={detailStyle} style={detailOffset} >
          <div className='row collapse align-right align-top'>
            <div className='columns' />
            <SVGInline className='bio-data-close-icon' svg={CloseIcon} />
          </div>

          <div className='row align-center align-top'>
            {this.props.item.HospitalTitle && this.createDetailRow('Hospital Title', this.props.item.HospitalTitle)}
            {this.props.item.Email && this.createDetailRow('Email', this.props.item.Email)}
            {this.props.item.Fellowship && this.createDetailRow('Fellowship', this.props.item.Fellowship)}
            {this.props.item.Residency && this.createDetailRow('Residency', this.props.item.Residency)}
            {this.props.item.MedicalSchool && this.createDetailRow('Medical School', this.props.item.MedicalSchool)}
            {this.props.item.AdditionalInformation && this.createDetailRow('Additional Info', this.props.item.AdditionalInformation)}
            {this.props.item.UndergraduateSchool && this.createDetailRow('Undergraduate School', this.props.item.UndergraduateSchool)}
            {this.props.item.MedicalInterests && this.createDetailRow('Medical Interests', this.props.item.MedicalInterests)}
            {this.props.item.FuturePlans && this.createDetailRow('Future Plans', this.props.item.FuturePlans)}
          </div>
        </div>
      </li>
    )
  }
}
