import React from 'react'
import URLS from '../../shared/constants/urls'
import emergencyIcon from '../../shared/assets/emergency.svg'
import infoIcon from '../../shared/assets/info-alt.svg'
import SVGInline from 'react-svg-inline'

export default class OnMyWayButton extends React.Component {
  render () {
    return <a href={`${URLS.mychartUrl}/Scheduling/OnMyWay/PostLogin`} className={`ed-action ${this.props.cms ? 'cms-action' : ''}`} target='_blank'>
      <span className='action-label'><SVGInline svg={emergencyIcon} /> I'M ON MY WAY</span>
      <div className='tooltip' onClick={(e) => { e.preventDefault(); document.querySelector('.circle').focus() }}>
        <div className='circle'><SVGInline svg={infoIcon} /></div>
        <div className='tip-text'>Sign in to MyChart to let the Emergency Department know you are on your way.</div>
      </div>
    </a>
  }
}
