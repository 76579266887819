import React from 'react'
import URLS from '../../../shared/constants/urls'
import './mychart-modal.scss'

export default class MyChartModal extends React.Component {
  componentDidMount () {
    this.button.focus()
  }

  render () {
    return (
      <div className='mychart-modal'>
        <div className='title'>Do you have an OhioHealth MyChart account?</div>
        <a className='btn btn-primary' ref={button => { this.button = button }} target='_blank' href={URLS.mychartApptUrl}>SIGN IN FOR FASTER SCHEDULING</a>
        <a tabIndex='0' className='continue-link' ref={link => { this.link = link }} onClick={this.props.submissionHandler}>Continue as a guest</a>
      </div>
    )
  }
}
