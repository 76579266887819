import React from 'react'
import Slider from 'react-slick'
import GalleryCard from './GalleryCard.jsx'
import sanitizeHtmlReact from 'sanitize-html-react'
import locationTransformer from '../../shared/data-mappers/location-page-transformer'
import LocationCard from '../../Search/components/SearchCards/LocationCard/LocationCard.jsx'
import ServiceCard from '../../Search/components/SearchCards/ServiceCard/ServiceCard.jsx'
import SVGInline from 'react-svg-inline'
import ArrowLeft from '../../shared/assets/arrow-left.svg'
import { addCarouselEvent } from '../../shared/utility-functions/googleTagManager'
import './slick.scss'
import './related-slider.scss'

export default class Carousel extends React.Component {
  constructor (props) {
    super(props)
    this.slides = this.slides.bind(this)
    this.slideSize = this.slideSize.bind(this)
    this.state = {
      slides: this.slides(),
      slide: 0,
      caption: true,
      slideSize: this.slideSize(),
      left: <button title='previous'><SVGInline className='icon' svg={ArrowLeft} /></button>,
      right: <button title='next'><SVGInline className='icon' svg={ArrowLeft} /></button>
    }
    window.addEventListener('resize', () => {
      this.setState({ slideSize: this.slideSize() })
    }, {passive: true})
    this.toggleCaption = this.toggleCaption.bind(this)
    this.onPreviousClick = this.onPreviousClick.bind(this)
    this.onNextClick = this.onNextClick.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
  }

  onPreviousClick () {
    addCarouselEvent('Previous Slide')
  }

  onNextClick () {
    addCarouselEvent('Next Slide')
  }

  toggleCaption () {
    let current = this.state.caption
    this.setState({ caption: !current })
  }

  slides () {
    switch (this.props.type) {
      case 'Gallery':
        return this.props.slides.map((slide, index) => {
          return <GalleryCard key={index} slide={slide} />
        })
      case 'Locations':
        return locationTransformer(this.props.slides).map((slide, index) => {
          slide.Name = this.setName(slide)
          return <LocationCard showInfo key={index} showHours location={slide} />
        })
      case 'Services':
        return this.props.slides.map((slide, index) => {
          return <ServiceCard key={index} service={slide} />
        })
    }
  }

  setName (card) {
    if (card.SearchNameOverride && card.SearchNameOverride.length > 0) { return card.SearchNameOverride }
    return card.Name
  }

  slideSize () {
    if (this.props.type === 'Locations') {
      if (window.innerWidth > 1370) {
        return 4
      } else if (window.innerWidth > 1020) {
        return 3
      } else if (window.innerWidth > 767) {
        return 2
      }
    } else if (this.props.type === 'Services') {
      if (window.innerWidth > 1370) {
        return 3
      } else if (window.innerWidth >= 960) {
        return 2
      }
    }
    return 1
  }

  manageVisbilityForAria () {
    const slides = document.querySelectorAll(".slick-slide")
    slides.forEach((slide) => {
      slide.style.visibility = slide.getAttribute('aria-hidden') === 'true' ? 'hidden' : 'visible';
    })
  }

  changeHandler (index) {
    if (index > this.state.slide) {
      this.onNextClick()
    }
    if (index < this.state.slide) {
      this.onPreviousClick()
    }
    this.setState({ slide: index })
    if (window.innerWidth < 1000) {
      let newSlide = document.querySelectorAll('.slick-slide')[index]
      newSlide.click()
    }
    this.manageVisbilityForAria()
  }

  render () {
    return <div className='slider-container'>
      <Slider accessibility onInit={this.manageVisbilityForAria} slidesToScroll={1} swipeToSlide infinite={false} arrows prevArrow={this.state.left} afterChange={this.changeHandler} nextArrow={this.state.right} slidesToShow={this.state.slideSize}>
        {this.state.slides}
      </Slider>
      {this.props.type !== 'Gallery' && <div className='slider-count show-for-small-only'>{this.state.slide + 1}/{this.state.slides && this.state.slides.length}</div>}
      {
        this.props.type === 'Gallery' &&
        <div className='controls-container'>
          <div className='gallery-controls'>
            <div className='gallery-counter'>{this.state.slide + 1}/{this.state.slides && this.state.slides.length}</div>
          </div>
          <div className={`gallery-caption ${this.state.caption ? '' : 'collapse'} ${this.props.slides[this.state.slide].Headline === '' && this.props.slides[this.state.slide].Caption === '' ? 'none' : ''}`}>
            <button className='gallery-caption-toggle' onClick={this.toggleCaption}>
              <span className='minus'>&ndash;</span>
              <span className='plus'>+</span>
            </button>
            <div className='gallery-caption-inner'>
              {this.props.slides[this.state.slide].Headline !== null && <div className='gallery-caption-heading'>{this.state.slide !== null ? this.props.slides[this.state.slide].Headline : ''}</div>}
              {this.props.slides[this.state.slide] && this.props.slides[this.state.slide].Caption != null && <div className='gallery-caption-copy' dangerouslySetInnerHTML={{__html: sanitizeHtmlReact(this.props.slides[this.state.slide ? this.state.slide : 0].Caption, {allowedTags: ['p', 'br', 'em', 'strong']})}} />}
            </div>
          </div>
        </div>
      }
    </div>
  }
}
