const formatMiliatryToNormal = (militaryTime) => {
  let formattedDate, hours, minutes

  hours = militaryTime.substring(0, 2)
  minutes = militaryTime.substring(2, 4)
  formattedDate = new Date()
  formattedDate.setHours(hours)
  formattedDate.setMinutes(minutes)

  return formatAMPM(formattedDate)
}

const formatAMPM = (date) => {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours
  if (minutes !== '00') {
    strTime = strTime + ':' + minutes
  }
  strTime = strTime + ' ' + ampm
  return strTime
}

const formatHoursMinutes = (time) => {
  let formattedTime
  formattedTime = ('0' + time).slice(-2)
  return formattedTime
}

const checkLocationCurrentlyOpen = (openTime, closeTime) => {
  let hours, minutes
  let currentTime = new Date()

  hours = formatHoursMinutes(currentTime.getHours())
  minutes = formatHoursMinutes(currentTime.getMinutes())

  let currentMilitaryTime = hours + minutes
  if (parseInt(openTime) < parseInt(currentMilitaryTime) && parseInt(closeTime) > parseInt(currentMilitaryTime)) {
    return true
  } else {
    return false
  }
}

const daysOfWeek = new Array(7)
daysOfWeek[0] = 'Sunday'
daysOfWeek[1] = 'Monday'
daysOfWeek[2] = 'Tuesday'
daysOfWeek[3] = 'Wednesday'
daysOfWeek[4] = 'Thursday'
daysOfWeek[5] = 'Friday'
daysOfWeek[6] = 'Saturday'

export {formatMiliatryToNormal, formatAMPM, checkLocationCurrentlyOpen, daysOfWeek, formatHoursMinutes}
