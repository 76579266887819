import throttle from 'lodash/throttle'
import React from 'react'
import './sticky-nav.scss'

const verticalScroll = (y) => {
  $('html, body').animate({ scrollTop: y }, 'slow')
}

const horizontalScroll = (x) => {
  $('.toc-nav').animate({ scrollLeft: x }, 'fast')
}

export default class StickyNav extends React.Component {
  constructor (props) {
    super(props)
    this.boundPageScrollHandler = this.boundPageScrollHandler.bind(this)
    this.bindEvents()
  }

  scrollOffset () {
    let node = document.querySelector('.toc-nav')
    if (parseInt(window.getComputedStyle(node).top) === Math.round(node.getBoundingClientRect().top)) {
      return node.offsetHeight + node.getBoundingClientRect().top
    }
    return node.offsetHeight
  }

  isElementInViewport (el) {
    if (!el) return false
    const bounds = el.getBoundingClientRect()
    let isMobile = $('.main-nav--search-mobile').is(':visible')
    let isSlimNavigation = $('.slim-navigation-container').is(':visible')
    let offset = this.scrollOffset()
    offset = isMobile ? offset += $('.main-nav').height() : offset
    offset = isSlimNavigation ? offset += $('.slim-navigation-container').height() : offset
    return (
      Math.round(bounds.top) + offset <= window.innerHeight && Math.round(bounds.bottom) > offset
    )
  }

  highlightNavItem (e) {
    const oldElInViewport = document.querySelector('.in-viewport')
    let node = document.querySelector('.toc-nav')
    let anyVisible = false

    document.querySelectorAll('.anchor-group-item').forEach(section => {
      if (this.isElementInViewport(section)) {
        anyVisible = true
      }
    })

    if (anyVisible) {
      document.querySelectorAll('.anchor-group-item').forEach((section) => {
        if (this.isElementInViewport(section)) {
          section.classList.add('in-viewport')
        } else {
          section.classList.remove('in-viewport')
        }
      })

      let tx = parseInt(window.getComputedStyle(document.querySelector('ol')).marginLeft) + parseInt(window.getComputedStyle(node).paddingLeft)
      const elInViewport = document.querySelector('.in-viewport')
      if (elInViewport) {
        const href = '#' + elInViewport.id
        let actNavItem = document.querySelector('.is-active')
        if (actNavItem && actNavItem.querySelector('a').getAttribute('href') !== href) {
          actNavItem.classList.remove('is-active')
        }
        actNavItem = document.querySelector(`a[href="${href}"]`)
        actNavItem = actNavItem.parentNode
        actNavItem.classList.add('is-active')

        // move selector above the selected item
        if (actNavItem) {
          tx += actNavItem.offsetLeft
        }
      }

      // scroll selected navigation item to the viewport
      if ((oldElInViewport !== elInViewport || e.type === 'resize')) {
        horizontalScroll(tx - parseInt(window.getComputedStyle(node).paddingLeft))
      }

      // move and resize selector
      node.style.setProperty('--toc-nav-selector-x', ($('.is-active').offset().left + 'px'))
      node.style.setProperty('--toc-nav-selector-width', ($('.is-active > a > span').width() + 'px'))
    }
  }

  toggleNavBottomShadow () {
    let node = document.querySelector('.toc-nav')
    if (parseInt(window.getComputedStyle(node).top) === Math.round(node.getBoundingClientRect().top)) {
      node.classList.add('has-bottom-shadow')
    } else {
      node.classList.remove('has-bottom-shadow')
    }
  }

  boundPageScrollHandler (e) {
    this.highlightNavItem(e)
    this.toggleNavBottomShadow()
  }

  bindEvents () {
    // higlight toc nav item on scroll
    window.addEventListener('scroll', throttle(this.boundPageScrollHandler, 700))
    window.addEventListener('resize', throttle(this.boundPageScrollHandler, 700))
  }

  handleClick (e) {
    e.preventDefault()
    $('.toc-nav li').removeClass('is-active')
    let selectedId = $(`#${e.target.className}`)
    if ($(selectedId).length) {
      let isMobile = $('.main-nav--search-mobile').is(':visible')
      let isSlimNavigation = $('.slim-navigation-container').is(':visible')
      let offset = $('#sticky-nav-container').height()
      offset = isMobile ? offset += $('.main-nav').height() : offset
      offset = isSlimNavigation ? offset += $('.slim-navigation-container').height() : offset
      verticalScroll($(selectedId).offset().top - offset)
      window.location.href = `#${e.target.className}`
    }
  }

  render () {
    return <nav className={`toc-nav ${this.props.displayNumbers ? 'numbered' : ''}`}>
      <div className='toc-nav__selector' />
      <ol className='wrapper'>
        {
          this.props.links.map(section => {
            return <li>
              <a className={`${this.props.displayNumbers ? 'numbers' : ''}`} href={`#${section.id}`} onClick={this.handleClick}><span className={`${section.id}`}>{section.name}</span></a>
            </li>
          })
        }
      </ol>
    </nav>
  }
}
