import React from 'react'
import DOBModal from './DOBModal'
import ErrorModal from './ErrorModal'
import QuestionsModal from './QuestionsModal'
import URLS from '../../shared/constants/urls'
import Modal from '../../shared/components/Modal'
import moment from 'moment'
import MammService from './services/MammService'
import noticeIcon from '../../shared/assets/info.svg'
import { MAMM_DOB_PROMPT, MAMM_DOB_TEXT } from '../../shared/constants/messages'
import NoticeModal from './NoticeModal'

export default class QuestionnaireModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      step: 0,
      error: null,
      visitType: window.sessionStorage.getItem('VisitTypeID'),
      notice: null
    }
    this.today = moment(new Date())
    this.inner = this.inner.bind(this)
    this.birthSubmission = this.birthSubmission.bind(this)
    this.submissionHandler = this.submissionHandler.bind(this)
    this.handleStepChange = this.handleStepChange.bind(this)
    this.momentDateStringFormat = 'MM-DD-YYYY'
  }

  componentDidMount () {
    if (!this.state.visitType) {
      this.setState({ step: 1 })
    } else {
      this.setState({ step: 0 })
    }
  }

  birthSubmission (date) {
    const currentAgeInMonths = this.today.diff(moment(date, this.momentDateStringFormat), 'months')
    let age = this.today.diff(moment(date, 'MM-DD-YYYY'), 'months')

    if (age <= 478) {
      this.setState({
        error: {
          icon: noticeIcon,
          text: 'You must be at least 40 years of age to schedule your mammogram online. Please contact your provider.',
          url: URLS.findADoctorUrl,
          urlTitle: 'Find a Provider'
        }
      })
    }
    else if (age <= 479) {
      this.setState({
        notice: {
          icon: noticeIcon
        }
      })
      window.sessionStorage.setItem('qDate', moment(date, this.momentDateStringFormat).format(this.momentDateStringFormat))
      this.setState({ step: 2 })
    } else {
      window.sessionStorage.setItem('qDate', moment(date, this.momentDateStringFormat).format(this.momentDateStringFormat))
      this.setState({ step: 3, service: MammService(Math.floor(currentAgeInMonths / 12)) })
    }
  }

  handleStepChange(newStep) {
    let date = window.sessionStorage.getItem('qDate');
    let age = this.today.diff(moment(date, 'MM-DD-YYYY'), 'months')
    this.setState({ step: newStep, service: MammService(Math.floor(age / 12)) });
  }

  submissionHandler (action) {
    if (action && action.error) {
      action.error.icon = noticeIcon
      this.setState({ error: action.error })
    } else {
      let existing = window.sessionStorage.getItem('VisitTypeID')
      if (existing) {
        this.setState({ step: 0 })
      } else {
        this.setState({
          error: {
            icon: noticeIcon,
            text: 'An error occurred. Please refresh the page and try again.'
          }
        })
      }
    }
  }

  componentDidUpdate (prevState) {
    if (prevState.step !== this.state.step) {
      document.activeElement.blur()
    }
  }

  inner () {
    if (!this.state.error) {
      switch (this.state.step) {
        case 1:
              return <DOBModal prompt={MAMM_DOB_PROMPT} title={"Let's Get Started"} text={MAMM_DOB_TEXT} submissionHandler={this.birthSubmission} />
        case 2: 
            return <NoticeModal notice={this.state.notice} onStepChange={this.handleStepChange} />
        case 3:
          return <QuestionsModal title={'Pre-qualifying Questions'} service={this.state.service} submissionHandler={this.submissionHandler} flow='mam' />
        default:
          return <div />
      }
    } else {
      return <ErrorModal error={this.state.error} />
    }
  }

  render () {
    return (<Modal hideFunction={() => { window.location.href = `${URLS.gatewayPageUrl}` }} showModal={this.state.step > 0 || this.state.error}>
      {this.inner()}
    </Modal>)
  }
}
