import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import searchSaga from './sagas/classes-search-saga'
import autocompleteClassesSaga from '../../shared/saga/fetch-classes-autocomplete-data-saga'
import classesReducer from './state/classes-reducer'
import ClassesSearchConnector from './ClassesSearchConnector.jsx'

export default class Root extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.store = createStore(classesReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
    sagaMiddleware.run(searchSaga)
    sagaMiddleware.run(autocompleteClassesSaga)
  }

  render () {
    return (
      <Provider store={this.store}>
        <ClassesSearchConnector headline={this.props.headline} isBlock={this.props.isBlock} filterOptions={this.props.filterOptions} searchOptions={this.props.searchOptions} />
      </Provider>
    )
  }
}
