import './gallery.scss'

const GalleryCard = ({ slide }) => {
  return <div className='gallery-slide swiper-slide'>
    {slide.Type === 'Image' && <img alt={slide.Headline} className='gallery-image' src={slide.Content} />}
    {slide.Type === 'Video' && <div className='video-component' dangerouslySetInnerHTML={{ __html: slide.Content }} />}
  </div>
}

export default GalleryCard
