import React from 'react'
import SVGInline from 'react-svg-inline'
import DropdownArrow from '../../shared/assets/DropdownArrow.svg'
import { getParameterByName } from '../../shared/utility-functions/searchParams.js'
import { createBrowserHistory } from 'history'
import './classes-events.scss'

let base = window.location.pathname
base = base.replace(/\/$/, '')

const history = createBrowserHistory({
  basename: base
})

const listener = (location, action) => {
  if (action === 'PUSH') {
    window.scroll(0, 0)
  }
}

history.listen(listener)

const insertBrowserHistory = (paramsObject) => {
  let optionalParams = ''
  let params = paramsObject || {}
  let paramsArray = []

  if (params.location) paramsArray.push('location=' + params.location)
  if (params.category) paramsArray.push('category=' + params.category)
  if (params.page) paramsArray.push('page=' + params.page)

  if (paramsArray.length) {
    paramsArray.forEach((segment, index) => {
      if (index === 0) {
        optionalParams = optionalParams.concat('?' + segment)
      } else {
        optionalParams = optionalParams.concat('&' + segment)
      }
    })
    window.history.pushState(null, null, (window.location.pathname + encodeURI(optionalParams)))
  } else {
    window.history.pushState(null, null, window.location.pathname)
  }
}

export class CustomSelect extends React.Component {
  render () {
    return <select aria-label={this.props.placeholder} className='custom-select' onChange={this.props.onChange} defaultValue={this.props.value}>
      <option className='default' value=''>{this.props.placeholder}</option>
      {this.props.options && this.props.options.map(option => {
        return <option value={option}>{option}</option>
      })}
    </select>
  }
}

export class ClassesAndEvents extends React.Component {
  constructor (props) {
    super(props)
    let location = getParameterByName('location') ? getParameterByName('location') : getParameterByName('cne_filter_loc')
    let category = getParameterByName('category') ? getParameterByName('category') : getParameterByName('cne_filter_cat')
    let page = getParameterByName('page')
    this.state = {
      open: null,
      location: location || null,
      category: category || null,
      pageSize: 10,
      pageNumber: page ? Number(page) : 1,
      locations: this.props.classes.reduce((a, b) => {
        if (b.loc && b.loc.length) {
          b.loc.forEach(location => {
            if (!a.includes(location)) {
              a.push(location)
            }
          })
        }
        return a
      }, []),
      categories: this.props.classes.reduce((a, b) => {
        if (b.cat && b.cat.length) {
          b.cat.forEach(category => {
            if (!a.includes(category)) {
              a.push(category)
            }
          })
        }
        return a
      }, [])
    }
    this.filteredClasses = this.filteredClasses.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.totalPages = this.totalPages.bind(this)
    this.updateUrlParams = this.updateUrlParams.bind(this)
    this.setFiltersFromURL = this.setFiltersFromURL.bind(this)
    this.card = this.card.bind(this)
    window.addEventListener('popstate', this.setFiltersFromURL, {passive: true})
  }

  nextPage () {
    let nextPage = this.state.pageNumber + 1
    this.setState({ pageNumber: nextPage })
    this.updateUrlParams()
  }

  prevPage () {
    let prevPage = this.state.pageNumber - 1
    this.setState({ pageNumber: prevPage })
    this.updateUrlParams()
  }

  setFiltersFromURL () {
    let tempParams = {}
    let location = getParameterByName('location') ? getParameterByName('location') : getParameterByName('cne_filter_loc')
    let category = getParameterByName('category') ? getParameterByName('category') : getParameterByName('cne_filter_cat')
    let page = getParameterByName('page')
    if (location) tempParams.location = location
    if (category) tempParams.category = category
    if (page) tempParams.pageNumber = Number(page)
    if (location || page || category) {
      this.setState(tempParams)
    }
  }

  updateUrlParams () {
    let tempParams = {}
    if (this.state.location) tempParams.location = this.state.location
    if (this.state.category) tempParams.category = this.state.category
    if (this.state.pageNumber) tempParams.page = this.state.pageNumber
    insertBrowserHistory(tempParams)
  }

  componentWillUnmount () {
    window.removeEventListener('popstate', this.setFiltersFromURL)
  }

  onPageChange (event) {
    let value = event.target.value
    if (value) {
      if (value <= this.totalPages() && value > 0) {
        this.setState({ pageNumber: value })
        this.updateUrlParams()
      }
    }
  }

  filteredClasses () {
    return this.props.classes.filter(c => this.state.location ? c.loc && c.loc.includes(this.state.location) : true).filter(c => this.state.category ? c.cat && c.cat.includes(this.state.category) : true).sort((a, b) => { return a.title > b.title ? 1 : -1 })
  }

  totalPages () {
    return Math.ceil(this.filteredClasses().length / this.state.pageSize)
  }

  toggle (index) {
    let current = this.state.open
    this.setState({ open: current === index ? null : index })
  }

  card (c, index) {
    return <div className={`class-event ${this.state.open === index ? 'open' : ''}`}>
      <h3 className='class-event--heading'>{c.title}</h3>
      {c.copy && <div className='class-event--copy' dangerouslySetInnerHTML={{ __html: c.copy }} />}
      {
        ((c.cat && c.cat.length) || (c.loc && c.loc.length)) &&
        <p className='class-event--info'>
          {c.cat && c.cat.length && <span><strong>Category:</strong> {c.cat.join(', ')}<br /></span>}
          {c.loc && c.loc.length && <span><strong>Location:</strong> {c.loc.join(', ')}<br /></span>}
        </p>
      }
      {
        c.slots && c.slots.length &&
        <div className='class-event--slots'>
          <button className='class-event--slots--actuator' onClick={() => { this.toggle(index) }}>
            <span className='plus'>+</span><span className='minus'>&ndash;</span>&nbsp;Dates &amp; Registration
          </button>
          <div className='class-event--slots--panel'>
            {c.add && <div className='class-event--slots--additional' dangerouslySetInnerHTML={{ __html: c.add }} />}
            {
              c.slots.map((s, index) => {
                return <div key={index} className='class-event--slot'>
                  {(s.loc || s.inst) && <p className='class-event--slot--info'>
                    {s.loc && <span><strong>Location:</strong> {s.loc}<br /></span>}
                    {s.inst && <span><strong>Instructor:</strong> {s.inst}<br /></span>}
                  </p>}
                  {s.reg && <p className='class-event--slot--reg'>
                    <strong>{(s.reg.phone && s.reg.phone.label) ? s.reg.phone.label : 'Register'}: </strong>
                    {s.reg.phone && s.reg.phone && <a href={`tel:${s.reg.phone.num}`}>{s.reg.phone.disp || s.reg.phone.num}</a>}
                    {s.reg.phone && s.reg.online && <span> or </span>}
                    {s.reg.online && s.reg.online.url && <a href={s.reg.online.url} target={s.reg.online.target} className='link-alt'>{s.reg.online.text || s.reg.online.url}</a>}
                  </p>}
                  {s.info && <p className='class-event--slot--additional'>{s.info}</p>}
                  {
                    s.dates && s.dates.length &&
                    <table className='hide-for-small-only'>
                      <tr>
                        <th>Date:</th>
                        <th>Day:</th>
                        <th>Time:</th>
                        <th>Fees:</th>
                      </tr>
                      {
                        s.dates.map((d, index) => {
                          return <tr key={index}>
                            <td>{d.date}</td>
                            <td>{d.day}</td>
                            <td>{d.time}</td>
                            <td>{d.fees}</td>
                          </tr>
                        })
                      }
                    </table>
                  }
                </div>
              })
            }
          </div>
        </div>
      }
    </div>
  }

  render () {
    return <div className='epi-block--wrapper classes-events-block'>
      <div className='epi-block--inner'>
        <div>
          <h2>{this.filteredClasses() && this.filteredClasses().length} Class{this.filteredClasses() && this.filteredClasses().length > 1 && 'es'} Offered</h2>
          <div className='class-event--filters'>
            <CustomSelect value={this.state.category} onChange={(event) => { this.setState({ category: event.target.value, open: null, pageNumber: 1 }, () => { this.updateUrlParams() }) }} options={this.state.categories} placeholder='Filter By Category' />
            <CustomSelect value={this.state.location} onChange={(event) => { this.setState({ location: event.target.value, open: null, pageNumber: 1 }, () => { this.updateUrlParams() }) }} options={this.state.locations} placeholder='Filter By Facility/Location' />
          </div>
          <div className='row'>
            <div className='column small-12 medium-6'>
              {
                this.filteredClasses().slice((this.state.pageNumber - 1) * this.state.pageSize, ((this.state.pageNumber - 1) * this.state.pageSize) + this.state.pageSize).map((c, index) => {
                  return index % 2 === 0 || window.innerWidth <= 767 ? this.card(c, index) : null
                })
              }
            </div>
            <div className='column small-12 medium-6 hide-for-small-only'>
              {
                this.filteredClasses().slice((this.state.pageNumber - 1) * this.state.pageSize, ((this.state.pageNumber - 1) * this.state.pageSize) + this.state.pageSize).map((c, index) => {
                  return index % 2 === 1 ? this.card(c, index) : null
                })
              }
            </div>
          </div>
          <div className='text-center paginationControls'>
            {
              this.totalPages() > 1 &&
              <div className='paginationControls-info'>
                {this.state.pageNumber > 1 &&
                <button className='paginationControls-prev' onClick={this.prevPage} aria-label='Previous page'><SVGInline className='icon' svg={DropdownArrow} /></button>}
                <input className='text-center current-page' type='text' value={this.state.pageNumber} onBlur={this.onPageChange} onKeyup={this.onPageChange} aria-label='Current page number' />
                <span className='separator'> of </span><span className='totalPages'>{this.totalPages()}</span>
                {this.state.pageNumber < this.totalPages() &&
                <button className='paginationControls-next' onClick={this.nextPage} aria-label='Next Page'><SVGInline className='icon' svg={DropdownArrow} /></button>}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
}

export default ClassesAndEvents
