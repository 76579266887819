import React from 'react'
import '../quizzes.scss'
import CategoryQuestion from './CategoryQuestion.jsx'
import { addQuizCompleteEvent } from '../../../shared/utility-functions/googleTagManager'

export default class ScoredQuiz extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showResults: false,
      questions: this.props.questions,
      totalAnswered: 0,
      total: this.props.questions ? this.props.questions.length : 0
    }
    this.score = this.score.bind(this)
    this.pick = this.pick.bind(this)
  }

  score () {
    let scores = this.state.questions.reduce((a, b) => {
      let found = a.filter((existing) => {
        return existing.category === b.answered
      })[0]
      if (found) {
        found.tally += 1
      } else {
        a.push({ category: b.answered, tally: 1 })
      }
      return a
    }, [])
    scores.sort((a, b) => {
      if (a.tally > b.tally) {
        return -1
      } else if (a.tally < b.tally) {
        return 1
      } else {
        return 0
      }
    })
    let longest = scores[0].tally
    let topCategory = scores.filter(score => {
      return score.tally === longest
    })
    if (topCategory.length >= 3) {
      this.setState({ showResult: true, categoryTitle: this.props.tie.categoryTitle, resultTitle: this.props.tie.resultsTitle, resultCopy: this.props.tie.content, resultImage: this.props.tie.image })
      addQuizCompleteEvent(this.props.title)
    } else if (topCategory.length === 2) {
      let range1 = this.props.ranges.filter((r) => {
        return r.number === topCategory[0].category
      })[0]
      let range2 = this.props.ranges.filter((r) => {
        return r.number === topCategory[1].category
      })[0]
      let final = Number(range1.priority) < Number(range2.priority) ? range1 : range2
      this.setState({ showResult: true, categoryTitle: final.categoryTitle, resultTitle: final.resultsTitle, resultCopy: final.content, resultImage: final.image })
      addQuizCompleteEvent(this.props.title)
    } else if (topCategory.length === 1) {
      let range = this.props.ranges.filter((r) => {
        return r.number === topCategory[0].category
      })[0]
      this.setState({ showResult: true, categoryTitle: range.categoryTitle, resultTitle: range.resultsTitle, resultCopy: range.content, resultImage: range.image })
      addQuizCompleteEvent(this.props.title)
    }
  }

  pick (questionIndex, answer) {
    if (!this.state.questions[questionIndex].answered) {
      let totalAnswered = this.state.totalAnswered
      this.setState({ totalAnswered: totalAnswered += 1 })
    }
    this.state.questions[questionIndex].answered = answer
  }

  render () {
    return <div className='epi-block category-quiz full'>
      {!this.state.showResult &&
        <div className='categorized-question-block epi-blocks'>
          {
            this.props.questions.map((question, index) => {
              return <CategoryQuestion question={question} key={index + 4} total={this.state.total} index={index} clickHandler={this.pick} />
            })
          }
        </div>
      }
      {
        !this.state.showResult &&
        <div className='row'>
          <div className='small-12 columns'>
            <button type='button' className='btn results-btn' disabled={this.state.totalAnswered < this.state.total} onClick={(event) => { event.stopPropagation(); this.score() }}>Get My Results</button>
          </div>
        </div>
      }
      {
        this.state.showResult &&
        <div className='quiz-results' style={`background-image: url('${this.state.resultImage}')`}>
          <div className='title'>
            <div className='category-score'>
              <span className='category-result'>{this.state.categoryTitle}</span>
            </div>
          </div>
          <div className='results-content'>
            <div className='score-content'>
              <div className='category-content' dangerouslySetInnerHTML={{ __html: this.state.resultCopy }} />
              <div className='disclaimer' dangerouslySetInnerHTML={{ __html: this.props.resultsDisclaimerText }} />
            </div>
            {this.props.relatedLinks && this.props.relatedLinks.length > 0 &&
              <div className='related-links'>
                <div className='related-links-title'>Other Quiz Topics</div>
                {
                  this.props.relatedLinks.map((link, index) => {
                    return <a href={link.href} key={index} target={link.target ? link.target : '_self'} alt={link.label}>{link.label}</a>
                  })
                }
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
}
