import React from 'react'
import moment from 'moment'
import { isDateOfBirthValid, isMonthValid, isDayValid, isYearValid } from '../../../shared/validators/date-of-birth-validator'
import './dob-modal.scss'

export default class DOBModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dirty: false,
      month: '',
      day: '',
      year: '',
      monthValid: false,
      dayValid: false,
      yearValid: false,
      monthDirty: false,
      dayDirty: false,
      yearDirty: false
    }
    this.submit = this.submit.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.showDOBError = this.showDOBError.bind(this)
    this.singularFieldError = this.singularFieldError.bind(this)
    this.showSectionError = this.showSectionError.bind(this)
    this.allFieldsFilled = this.allFieldsFilled.bind(this)
    this.showDateError = this.showDateError.bind(this)
    this.validateField = this.validateField.bind(this)
  }

  componentDidMount () {
    this.month.focus()
  }

  changeHandler (event, maxLength) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      if (event.target.value.length === maxLength) {
        let nextElement = null
        switch (event.target.name) {
          case 'month':
            nextElement = this.day
            break
          case 'day':
            nextElement = this.year
            break
          case 'year':
            nextElement = this.button
            break
        }
        if (nextElement) {
          nextElement.focus()
        }
      }
    })
  }

  showDOBError () {
    return this.singularFieldError() || this.showDateError()
  }

  singularFieldError () {
    return this.showSectionError('month') || this.showSectionError('day') || this.showSectionError('year')
  }

  showSectionError (name) {
    switch (name) {
      case 'day':
        return this.state.dayDirty && !this.state.dayValid
      case 'month':
        return this.state.monthDirty && !this.state.monthValid
      case 'year':
        return this.state.yearDirty && !this.state.yearValid
    }
  }

  allFieldsFilled () {
    return !!(this.state.day && this.state.month && (this.state.year && this.state.year.length === 4))
  }

  showDateError () {
    if (!this.allFieldsFilled()) {
      return false
    }
    let date = moment(`${Number(this.state.year)}-${Number(this.state.month)}-${Number(this.state.day)}`, 'YYYY-MM-DD')
    return !date.isValid() || !isDateOfBirthValid({ day: this.state.day, month: this.state.month, year: this.state.year })
  }

  validateField (event) {
    let name = event.target.name
    let value = event.target.value
    switch (name) {
      case 'month':
        this.setState({ monthValid: isMonthValid(value), monthDirty: true })
        break
      case 'day':
        this.setState({ dayValid: isDayValid(value), dayDirty: true })
        break
      case 'year':
        this.setState({ yearValid: isYearValid(value), yearDirty: true })
        break
      default:
        break
    }
    this.setState({ dirty: true })
  }

  submit (event) {
    event.preventDefault()
    let errorForm = this.showDOBError()
    if (!errorForm) {
      this.props.submissionHandler(`${this.state.month}-${this.state.day}-${this.state.year}`)
    }
  }

  render () {
    let allFilled = this.allFieldsFilled()
    let fieldError = this.singularFieldError()
    let dateError = this.showDateError()
    let invalidForm = this.showDOBError()
    return (
      <div className='dob-modal'>
        <div className='title'>{this.props.title}</div>
        <p className='explanation'>{this.props.prompt}</p>
        <form onSubmit={this.submit}>
          <div className={dateError && allFilled && !fieldError ? 'date-of-birth-input date-of-birth-input--error' : 'date-of-birth-input'}>
            <label required className='text-center'>Date of Birth</label>
            <input aria-label='month' type='number' ref={month => { this.month = month }} id='month' name='month' className={`field__input month ${this.showSectionError('month') ? 'field__input--error' : ''}`} placeholder='MM' onBlur={this.validateField} onChange={(event) => { this.changeHandler(event, 2) }} min='1' max='12' value={this.state.month} />
            <input aria-label='day' type='number' ref={day => { this.day = day }} id='day' name='day' className={`field__input day ${this.showSectionError('day') ? 'field__input--error' : ''}`} placeholder='DD' onBlur={this.validateField} onChange={(event) => { this.changeHandler(event, 2) }} min='1' max='31' value={this.state.day} />
            <input aria-label='year' type='number' ref={year => { this.year = year }} id='year' name='year' className={`field__input year ${this.showSectionError('year') ? 'field__input--error' : ''}`} placeholder='YYYY' onBlur={this.validateField} onChange={(event) => { this.changeHandler(event, 4) }} min='1900' value={this.state.year} />
            {(dateError || fieldError) && this.state.dirty && <div className='field__error show-for-small-only'>Please enter a valid date between Jan 1, 1900 and today.</div>}
            <button type='submit' ref={button => { this.button = button }} disabled={invalidForm || !allFilled} className={`btn begin-button ${(invalidForm || !allFilled) ? 'button-disabled' : ''}`}>Begin</button>
          </div>
          {(dateError || fieldError) && this.state.dirty && <div className='field__error hide-for-small-only'>Please enter a valid date between Jan 1, 1900 and today.</div>}
          <p className='note'><small>{this.props.text}</small></p>
        </form>
      </div>
    )
  }
}
