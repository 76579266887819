import { connect } from 'react-redux'
import LandingPage from './LandingPage.jsx'

const mapStateToProps = (state, ownProps) => {
  return ({
    app: false,
    ...ownProps
  })
}

const mapDispatchToProps = (dispatch) => ({
  fetchDoctorData: () => dispatch({
    type: 'GET_AUTOCOMPLETE_DATA',
    org: '',
    addKey: '',
    ids: '',
    app: false
  }),
  fetchLocationData: () => dispatch({
    type: 'GET_LOCATIONS_AUTOCOMPLETE_DATA',
    ids: ''
  }),
  fetchServiceData: () => dispatch({
    type: 'GET_SERVICES_AUTOCOMPLETE_DATA',
    ids: ''
  }),
  fetchClassesData: () => dispatch({
    type: 'GET_CLASSES_AUTOCOMPLETE_DATA',
    ids: ''
  })
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  initializePage: () => {
    if (stateProps.type === 'doctors') {
      dispatchProps.fetchDoctorData()
    } else if (stateProps.type === 'locations') {
      dispatchProps.fetchLocationData()
    } else if (stateProps.type === 'services') {
      dispatchProps.fetchServiceData()
    } else if (stateProps.type === 'classes') {
      dispatchProps.fetchClassesData()
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LandingPage)
