import React from 'react'
import './location-info.scss'
import {formatMiliatryToNormal, checkLocationCurrentlyOpen, daysOfWeek} from '../../shared/utility-functions/formatTime'
import {formatPhoneNumber} from '../../shared/utility-functions/phoneHelpers'
import SVGInline from 'react-svg-inline'
import chevronDown from '../../shared/assets/chevron-down.svg'
import sanitizeHtml from 'sanitize-html-react'
import chevronUp from '../../shared/assets/chevron-up.svg'
import { clickToCallEvent } from '../../shared/utility-functions/googleTagManager'
import { clickToCallTransformer } from '../../shared/data-mappers/click-to-call-transformer'

export default class ProfileRoot extends React.Component {
  constructor (props) {
    super(props)
    this.toggleHours = this.toggleHours.bind(this)
    this.state = {
      hoursToggle: false,
      hourList: this.renderHourList(),
      address: this.renderAddress(),
      phoneFax: this.renderPhoneFax(),
      showHours: this.checkShowHours(),
      headerTitle: this.renderHeaderTitle()
      }
    this.clickToCall = this.clickToCall.bind(this)
  }

  clickToCall(e, locationInfo) {
    e.stopPropagation()

    const facilityName = locationInfo.locationName
    const facilityAddress = {
        Address: `${locationInfo.address1} ${locationInfo.address2} ${locationInfo.address2}`,
        City: locationInfo.city,
        State: locationInfo.state,
        ZipCode: locationInfo.zipCode
    }
    const facilityPhoneNumber = formatPhoneNumber(locationInfo.phoneNumber)

    clickToCallEvent(clickToCallTransformer(this.props.flow, null, facilityName, facilityAddress, facilityPhoneNumber))
  }

  checkShowHours () {
    return !(!this.checkHoursAvailable(this.props.location.hours) && !this.props.location.generalHours)
  }

  checkHoursAvailable (hours) {
    let dayList
    if (hours) {
      dayList = Object.keys(hours)
    }

    if (dayList) {
      for (let i = 0; i < dayList.length; i++) {
        const day = dayList[i]
        let open = hours[day].open
        let close = hours[day].close

        if (open && close) {
          return true
        }
      }
    }

    return false
  }

  toggleHours () {
    this.setState(prevState => {
      return { hoursToggle: !prevState.hoursToggle }
    })
  }

  mapsSelector () {
    let fullAddress = this.props.location.address1 + ',' + this.props.location.address2 + ' ' + this.props.location.city + ',' + this.props.location.state + ' ' + this.props.location.zipCode

    if ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1)) {
      window.open('maps://maps.google.com/maps?daddr=' + fullAddress + '&amp;ll=')
    } else {
      window.open('https://maps.google.com/maps?daddr=' + fullAddress + '&amp;ll=')
    }
  }

  getTodaysHours () {
    let todaysHours = {}
    let currentTime = new Date()
    let day = currentTime.getDay()

    if (this.props.location.hours && this.props.location.hours[daysOfWeek[day].toLowerCase()]) {
      todaysHours.open = this.props.location.hours[daysOfWeek[day].toLowerCase()].open
      todaysHours.close = this.props.location.hours[daysOfWeek[day].toLowerCase()].close
    } else {
      todaysHours = null
    }

    return todaysHours
  }

  renderHeaderTitle () {
    let specialtyString = ''
    if (this.props.location.specialties) {
      let titleToggle = true
      this.props.location.specialties.forEach(function (element, index) {
        if (index === 0) {
          specialtyString = element.Text
        } else {
          specialtyString += ' | ' + element.Text
          titleToggle = false
        }
      })
      return <span className={titleToggle ? 'name-title' : 'specialty-title'}>{specialtyString}</span>
    } else {
      return <span className='name-title'>{this.props.location.locationName}</span>
    }
  }

  renderPhoneFax () {
    let singleNumberStyle = ''
    if ((this.props.location.phoneNumber && !this.props.location.faxNumber) || (this.props.location.faxNumber && !this.props.location.phoneNumber)) {
      singleNumberStyle = 'single-number-format'
    }
    return <div className='location-number-container'>
      {this.props.location.phoneNumber && <a className={'number-text opg-location-phone ' + singleNumberStyle} href={'tel:' + this.props.location.phoneNumber} onclick={(e) => this.clickToCall(e, this.props.location)}>P: {formatPhoneNumber(this.props.location.phoneNumber)}</a>}
      {this.props.location.faxNumber && <a className={'number-text opg-location-fax ' + singleNumberStyle} >F: {formatPhoneNumber(this.props.location.faxNumber)}</a>}
    </div>
  }

  renderTodaysHours () {
    let open
    let hoursAvailable = this.checkHoursAvailable(this.props.location.hours)
    let todaysHours = this.getTodaysHours()
    if (todaysHours) {
      open = checkLocationCurrentlyOpen(todaysHours.open, todaysHours.close)
    }
    let hoursLabel = ''
    if (open) {
      let start = formatMiliatryToNormal(todaysHours.open)
      let end = formatMiliatryToNormal(todaysHours.close)
      hoursLabel = <div className='time-label'>{start} {start.includes('AM') && end.includes('PM') ? 'to' : '-'} {end}</div>
    } else if (hoursAvailable) {
      hoursLabel = <div className='time-label'>CLOSED</div>
    }

    return <div className='hours-of-operation' onClick={this.toggleHours}>
      {hoursAvailable ? <div className='day-label'>Today's Hours</div> : <div>Hours of Operation</div>}
      {hoursLabel}
      <SVGInline className='toggle-icon' svg={this.state.hoursToggle ? chevronUp : chevronDown} />
    </div>
  }

  renderHourList () {
    let hourList = []
    let dayList = []

    if (this.props.location.hours) {
      dayList = Object.keys(this.props.location.hours)
    }

    if (this.checkHoursAvailable(this.props.location.hours)) {
      dayList.forEach(day => {
        let open = this.props.location.hours[day].open
        let close = this.props.location.hours[day].close
        let openTime, closeTime

        if (open && close) {
          openTime = formatMiliatryToNormal(open)
          closeTime = formatMiliatryToNormal(close)

          hourList.push(
            <li className='days-hours'>
              <div className='day-label'>{day}</div>
              <div className='time-label'>{openTime} {openTime.includes('AM') && closeTime.includes('PM') ? 'to' : '-'} {closeTime}</div>
            </li>
          )
        } else if (day !== 'sunday' && day !== 'saturday') {
          hourList.push(
            <li className='days-hours'>
              <div className='day-label'>{day}</div>
              <div className='time-label closed-label'>CLOSED</div>
            </li>
          )
        }
      })
      return hourList
    } else {
      return <div className='general-hours'>{sanitizeHtml(this.props.location.generalHours, { allowedTags: [] })}</div>
    }
  }

  renderAddress () {
    if (this.props.location.address1) {
      let fullAddress, cityStateZip
      if (this.props.location.address1) {
        fullAddress = this.props.location.address1
      }
      if (this.props.location.address2) {
        fullAddress += ', ' + this.props.location.address2
      }
      if (this.props.location.address3) {
        fullAddress += ', ' + this.props.location.address3
      }
      cityStateZip = this.props.location.city + ', ' + this.props.location.state + ' ' + this.props.location.zipCode

      return <span className='location-address-container' onClick={() => { this.mapsSelector() }}>
        <span className='full-address-container'>{fullAddress}</span>
        <span className='city-state-zip-container'>{cityStateZip}</span>
      </span>
    }
  }

  render () {
    let todaysHours = this.renderTodaysHours()

    return (
      <div className='location-info-container'>
        <h1 className='h1-opg-header-container'>
          {!this.props.location.hideOPGBanner && <span className='affiliation-header'>{this.props.affiliation}</span>}
          {/* Header Title */}
          {this.state.headerTitle}
          {/* Address */}
          {this.state.address}
        </h1>
        {/* Numbers */}
        {this.state.phoneFax}
        {/* Hours */}
        <div className={!this.state.showHours && 'display-none'}>
          {todaysHours}
          <ul className={this.state.hoursToggle ? 'hours-dropdown' : 'hours-dropdown display-none'}>
            {this.state.hourList}
          </ul>
        </div>
      </div>
    )
  }
}
