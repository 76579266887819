import React from 'react'
import '../quizzes.scss'
import ScoredQuestion from './ScoredQuestion.jsx'
import { addQuizCompleteEvent } from '../../../shared/utility-functions/googleTagManager'

export default class ScoredQuiz extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      step: 'intro',
      currentQuestion: 0,
      score: 0
    }
    this.clickHandler = this.clickHandler.bind(this)
    this.score = this.score.bind(this)
  }

  score () {
    this.props.ranges.forEach((range) => {
      if (this.state.score <= range.max && this.state.score >= range.min) {
        this.setState({ scoreTitle: range.title, scoreCopy: range.content })
      }
    })
  }

  clickHandler (point) {
    let currentScore = this.state.score
    let questionIndex = this.state.currentQuestion
    this.setState({ score: currentScore += point })
    if (questionIndex === this.props.questions.length - 1) {
      this.setState({ step: 'result', result: this.score() })
      addQuizCompleteEvent(this.props.title)
    } else {
      this.setState({ currentQuestion: questionIndex += 1 })
    }
  }

  render () {
    switch (this.state.step) {
      case 'intro':
        return <div className='start-quiz' style={`background-image: url('${this.props.backgroundImage}');`}>
          <div className='intro-title' dangerouslySetInnerHTML={{__html: this.props.introTitle}} />
          <p className='intro-message' dangerouslySetInnerHTML={{__html: this.props.introMessage}} />
          <button type='button' className='start-quiz-btn btn' onClick={() => { this.setState({ step: 'questions', currentQuestion: 0 }) }}>{this.props.ctaButtonText}</button>
        </div>
      case 'questions':
        return <div className='epi-blocks'>
          {
            this.props.questions.map((question, index) => {
              return index === this.state.currentQuestion && <ScoredQuestion key={index} index={index} total={this.props.questions.length} clickHandler={this.clickHandler} question={question} />
            })
          }
        </div>
      case 'result':
        return <div className='quiz-results slideIn' style={`background-image: url('${this.props.resultsBackgroundImage}');`}>
          <div className='score'>{this.state.score} / {this.props.questions.length}</div>
          <div className='results-content'>
            <div className='score-content'>
              <div className='score-title'>{this.state.scoreTitle}</div>
              <div className='score-copy' dangerouslySetInnerHTML={{ __html: this.state.scoreCopy }} />
              <div className='disclaimer' dangerouslySetInnerHTML={{ __html: this.props.resultsDisclaimerText }} />
            </div>
            {this.props.relatedLinks && this.props.relatedLinks.length > 0 &&
              <div className='related-links'>
                <div className='related-links-title'>Other Quiz Topics</div>
                {this.props.relatedLinks.map((link, index) => <a href={link.href} key={index} target={link.target ? link.target : '_self'}>{link.label}</a>)}
              </div>
            }
          </div>
        </div>
    }
  }
}
