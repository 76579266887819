import React from 'react'
import ReactPlayerLoader from '@brightcove/react-player-loader'
import SVGInline from 'react-svg-inline'
import './promo-block.scss'
import PlayButton from '../../shared/assets/Play.svg'
import XIcon from '../../shared/assets/x.svg'

export default class PromoBlock extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showVideo: false,
      videoPlayer: null,
      target: this.props.CTALinkTarget || '_self'
    }
    this.toggleVideo = this.toggleVideo.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
  }

  renderUrl(url) {
    return url.replace(/\+/g, '%20');
  }

  getImage() {
    let imageEl = <img alt='' aria-hidden='true' className='promo-tertiary--thumb' src={this.props.imageUrl} />
    return (
      <div className='promo-tertiary--thumb-wrapper'>
        {this.props.imageIsLink ?
          <a href={this.renderUrl(this.props.CTAUrl)} target={this.props.CTALinkTarget} aria-labelledby='headerText'>
            {imageEl}
          </a>
          : imageEl
        }
      </div>

    )
  }

  getHeadline() {
    const headlineEl = <h3 className={`promo-tertiary--heading ${this.props.headlineIsLink ? 'underlineOnHover' : ''} `} id='headerText'><span dangerouslySetInnerHTML={{ __html: this.props.headline }} /></h3>;
    return this.props.headlineIsLink ? (
      <a href={this.renderUrl(this.props.CTAUrl)} target={this.props.CTALinkTarget}>
        {headlineEl}
      </a>
    ) : headlineEl;
  }

  renderPromoTertiary () {
    return <div className={`epi-block--wrapper promo-tertiary ${this.props.id}`}>
      {this.props.greyBackground && <div className='epi-block--background-overlay' />}
      <div className='epi-block--inner'>
        <div className='promo-tertiary--inner'>
          {this.props.imageUrl && this.getImage()}
          {this.props.subHeadline && <h5 className='promo-tertiary--subheading' dangerouslySetInnerHTML={{ __html: this.props.subHeadline }} />}
          {this.props.headline && this.getHeadline()}
          {this.props.copy && <p className='promo-tertiary--copy' dangerouslySetInnerHTML={{ __html: this.props.copy }} />}
          {this.props.hasCTA && <a className='btn btn-primary promo-primary--btn' title='cta link' onClick={ (e) => {this.props.hasVideo ? (e.preventDefault(), this.toggleVideo()) : this.toggleVideo} } href={this.renderUrl(this.props.CTAUrl)} target={this.props.CTALinkTarget}>{this.props.CTAText}</a>}
        </div>
      </div>
    </div>
  }

  renderPromoSecondary () {
    return <div className={`epi-block--wrapper promo-secondary ${this.props.id}`} data-interchange={`[${this.props.backgroundImage}, small], [${this.props.backgroundImage}, medium]`}>
      <div className='epi-block--inner'>
        <div className='promo-secondary--inner'>
          {this.props.headline && <h3 className='promo-secondary--heading' dangerouslySetInnerHTML={{ __html: this.props.headline }} />}
          {this.props.copy && <p className='promo-secondary--copy' dangerouslySetInnerHTML={{ __html: this.props.copy }} />}
          {this.props.CTAUrl && this.props.CTAText && <p className='learn-more'><a title='learn more' href={this.renderUrl(this.props.CTAUrl)} target={this.state.target} className='link-alt faux-a'>{this.props.CTAText}</a></p>}
          {this.props.hasVideo && this.props.CTAText && <p className='learn-more'><button title='play video' onClick={this.toggleVideo} className='link-alt faux-a'>{this.props.CTAText}</button></p>}
        </div>
      </div>
    </div>
  }

  renderPromoSecondaryOverlay () {
    return <div className={`epi-block--wrapper promo-secondary promo-secondary-overlay ${this.props.id}`} data-interchange={`[${this.props.backgroundImage}, small], [${this.props.backgroundImage}, medium]`}>
      <div className='epi-block--background-overlay' style={`background-color:${this.props.overlayColor};`} />
      <div className='epi-block--inner'>
        <div className='promo-secondary--inner'>
          {
            this.props.icon &&
            <div className='promo-secondary--icon'>
              <div className='promo-secondary--icon-overlay' style={`background-color:${this.props.overlayColor};`} />
              <svg className='icon'>
                <use href={`/ClientResources/Website/images/svg/sprite.symbol.svg#icons--${this.props.icon}`} />
              </svg>
            </div>
          }
          {this.props.headline && <h3 className='promo-secondary--heading' dangerouslySetInnerHTML={{ __html: this.props.headline }} />}
          {this.props.copy && <p className='promo-secondary--subheading' dangerouslySetInnerHTML={{ __html: this.props.copy }} />}
          {this.props.CTAUrl && this.props.CTAText && <a className='btn btn-primary promo-secondary--btn' href={this.renderUrl(this.props.CTAUrl)} target={this.state.target}>{this.props.CTAText}</a>}
          {this.props.hasVideo && this.props.CTAText && <button title='play button' className='btn btn-primary promo-secondary--btn' onClick={this.toggleVideo}>{this.props.CTAText}</button>}
        </div>
      </div>
    </div>
  }

  renderPromoPrimary () {
    return <div className={`epi-block--wrapper promo-primary ${this.props.id} ${this.props.copyAppearance} ${this.props.copyLocation}`}>
      <div className='epi-block--background' data-interchange={`[${this.props.mobileBackgroundImage || this.props.backgroundImage}, small], [${this.props.backgroundImage}, medium]`} />
      <div className='epi-block--inner'>
        <div className='promo-primary--inner'>
          {this.props.subHeadline && <h4 className='promo-primary--subheading' dangerouslySetInnerHTML={{ __html: this.props.subHeadline }} />}
          {this.props.headline && <h3 className='promo-primary--heading' dangerouslySetInnerHTML={{ __html: this.props.headline }} />}
          {this.props.copy && <p className='promo-primary--copy' dangerouslySetInnerHTML={{ __html: this.props.copy }} />}
          {this.props.copyAppearance === 'square' && this.props.CTAUrl && <p className='learn-more'><a href={this.props.CTAUrl} target={this.state.target} className='faux-a link-alt'>{this.props.CTAText}</a></p>}
          {this.props.copyAppearance === 'circle' && this.props.CTAUrl && <a className='btn btn-primary promo-primary--btn' href={this.props.CTAUrl} target={this.state.target}>{this.props.CTAText}</a>}
          {this.props.hasVideo && <button title='play button' className='promo-primary--btn play-btn' onClick={this.toggleVideo}><SVGInline className='icon' svg={PlayButton} /></button>}
        </div>
      </div>
    </div>
  }

  toggleVideo () {
    this.state.showVideo ? this.state.videoPlayer.pause() : this.state.videoPlayer.play()
    this.setState(prevState => {
      return { showVideo: !prevState.showVideo }
    })
  }

  renderVideo () {
    return <div className={this.state.showVideo ? 'inline-window--wrapper' : 'inline-window--wrapper display-none'} style='display: block; opacity: 1;'>
      <div className='inline-window--bg' />
      <div className='inline-window'>
        <div className='inline-window--content-area'>
          <div className='inline-content inline-window--content'>
            <div className='video-component' aria-hidden='true'><ReactPlayerLoader accountId={this.props.accountId} applicationId={this.props.applicationId} playerId={this.props.playerId ? this.props.playerId : 'rkOSfMOe'} videoId={this.props.videoId} onSuccess={this.onSuccess} /></div>
          </div>
        </div>
        <a className='btn inline-window--close-btn' target='_self' name='Close' title='Close button' onClick={this.toggleVideo}>
          <SVGInline className='icon' alt='Close' svg={XIcon} />
        </a>
      </div>
    </div>
  }

  onSuccess (success) {
    this.setState({
      videoPlayer: success.ref
    })
  }

  renderPromo () {
    if (this.props.type === 'primary') {
      return this.renderPromoPrimary()
    } else if (this.props.type === 'secondary') {
      return this.renderPromoSecondary()
    } else if (this.props.type === 'secondary-overlay') {
      return this.renderPromoSecondaryOverlay()
    } else if (this.props.type === 'tertiary') {
      return this.renderPromoTertiary()
    }
  }

  render () {
    return (
      <div>
        {this.renderPromo()}
        {this.props.hasVideo && this.renderVideo()}
      </div>
    )
  }

}
