import { race, put, take, delay } from 'redux-saga/effects'
import {fetchData} from '../../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../../shared/constants/wait-times'
import { CLASSES_ENDPOINT } from '../../../shared/constants/api-helpers.js'
import classesTransformer from '../../../shared/data-mappers/classes-transformer.js'

export default function * insuranceSearchSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_CLASSES')
    let params = action.params ? Object.assign({}, action.params) : {}
    params.endPoint = CLASSES_ENDPOINT
    yield put({ type: 'SEARCH_CLASSES' })
    yield put({ type: 'CLEAR_ITEMS' })
    yield put({ type: 'SET_CALL_FAILED', value: false })

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.success) {
      yield put({ type: 'SET_ITEMS', value: classesTransformer(posts.results) })
    } else {
      yield put({ type: 'SET_CALL_FAILED', value: true })
    }
  }
}
