import moment from 'moment'

const classesTransformer = (data) => {
  return data && data.length ? data.reduce((a, b) => {
    let found = a.find(clas => {
      return clas.Name === b.Name && clas.Location === b.Location
    })
    if (found) {
      addDates(b.Dates, found.Dates)
      addFees(b.Fees, found.Fees)
      addWeeks(b.Weeks, found.Weeks)
      sortDates(found)
      sortWeeks(found)
      found.classes.push(b)
    } else {
      let newClass = {...b, Description: b.Description.replace('Â', '').replace('â„¢', '™'), Name: b.Name.replace('Â', '').replace('â„¢', '™'), Fees: [], classes: [b], Dates: [], Weeks: []}
      addFees(b.Fees, newClass.Fees)
      addDates(b.Dates, newClass.Dates)
      addWeeks(b.Weeks, newClass.Weeks)
      sortDates(newClass)
      sortWeeks(newClass)
      a.push(newClass)
    }
    return a
  }, []) : []
}

const addDates = (dates, list) => {
  if (dates && dates.length) {
    dates.forEach(dat => {
      let exists = list.find(d => {
        return d.Date === dat.Date && d.StartTime === dat.StartTime && d.EndTime === dat.EndTime
      })
      if (!exists) {
        list.push(dat)
      }
    })
  }
}

const addWeeks = (weeks, list) => {
  if (weeks && weeks.length) {
    weeks.forEach(week => {
      let exists = list.find(d => d.StartDate === week.StartDate && d.EndDate === week.EndDate && d.Day === week.Day && d.StartTime === week.StartTime && d.EndTime === week.EndTime)
      if (!exists) {
        list.push(week)
      }
    })
  }
}

const addFees = (fees, list) => {
  if (fees && fees.length) {
    fees.forEach(fee => {
      let exists = list.find(f => f.Cost === fee.Cost && fee.Target === f.Target && fee.Unit === f.Unit && f.Session === fee.Session)
      if (!exists) {
        list.push(fee)
      }
    })
  }
}

const sortDates = (input) => {
  if (input.Dates && input.Dates.length) {
    input.Dates.sort((a, b) => {
      if (moment(a.Date, 'M/DD/YYYY HH:mm:ss A') > moment(b.Date, 'M/DD/YYYY HH:mm:ss A')) {
        return 1
      } else if (moment(a.Date, 'M/DD/YYYY HH:mm:ss A') < moment(b.Date, 'M/DD/YYYY HH:mm:ss A')) {
        return -1
      } else if (moment(a.Date, 'M/DD/YYYY HH:mm:ss A') === moment(b.Date, 'M/DD/YYYY HH:mm:ss A')) {
        if (a.StartTime > b.StartTime) {
          return 1
        } else if (a.StartTime < b.StartTime) {
          return -1
        } else {
          return 0
        }
      }
    })
  }
}

const sortWeeks = (input) => {
  if (input.Weeks && input.Weeks.length) {
    input.Weeks.sort((a, b) => {
      if (moment(a.StartDate, 'M/DD/YYYY HH:mm:ss A') > moment(b.StartDate, 'M/DD/YYYY HH:mm:ss A')) {
        return 1
      } else if (moment(a.StartDate, 'M/DD/YYYY HH:mm:ss A') < moment(b.StartDate, 'M/DD/YYYY HH:mm:ss A')) {
        return -1
      } else if (moment(a.StartDate, 'M/DD/YYYY HH:mm:ss A') === moment(b.StartDate, 'M/DD/YYYY HH:mm:ss A')) {
        if (a.StartTime > b.StartTime) {
          return 1
        } else if (a.StartTime < b.StartTime) {
          return -1
        } else {
          return 0
        }
      }
    })
  }
}

export default classesTransformer
