import { createSelector } from 'reselect'
import {insuranceSorter} from '../../../shared/sorters'

const allItems = state => state.items
export const getFilters = state => state.filters
export const getSort = state => state.sort

export const getPlansByProvider = createSelector(allItems, getFilters, (items, filters) => {
  return items.filter(item => {
    return filters.selectedProviders && filters.selectedProviders.length ? filters.selectedProviders.filter(provider => {
      return provider === item.provider
    })[0] : true
  })
})

export const getPlansByLocationGroup = createSelector(allItems, getFilters, (items, filters) => {
  return items.filter(item => {
    return filters.selectedLocationGroups && filters.selectedLocationGroups.length ? filters.selectedLocationGroups.filter(group => {
      return item.groups.filter(itemGroup => {
        return itemGroup === group
      })[0]
    })[0] : true
  })
})

export const getFilteredItems = createSelector(allItems, getSort, getPlansByLocationGroup, getPlansByProvider, (items, sort, locationFilter, providerFilter) => {
  return insuranceSorter(items.filter(n => providerFilter.includes(n)).filter(n => locationFilter.includes(n)), sort)
})

export const getSortOptions = () => {
  return [{ value: 'A TO Z', label: 'Name: A TO Z' },
    { value: 'Z TO A', label: 'Name: Z TO A' }]
}

export const getLocationGroupsFromAllPlans = createSelector(getFilteredItems, (items) => {
  return items.reduce((a, b) => {
    if (b.groups && b.groups.length) {
      b.groups.forEach(group => {
        let found = a.filter(groupList => groupList === group)[0]
        if (!found) {
          a.push(group)
        }
      })
    }
    return a
  }, []).sort((a, b) => { return a.toLowerCase() > b.toLowerCase() ? 1 : -1 })
})

export const getProvidersFromAllPlans = createSelector(getFilteredItems, (items) => {
  return items.reduce((a, b) => {
    if (!a.filter(provider => b.provider === provider)[0]) a.push(b.provider)
    return a
  }, []).sort((a, b) => { return a.toLowerCase() > b.toLowerCase() ? 1 : -1 })
})

export const getFilterCount = createSelector(getFilters, (filters) => {
  let count = 0
  if (filters.selectedProviders && filters.selectedProviders.length) count += 1
  if (filters.selectedLocationGroups && filters.selectedLocationGroups.length) count += 1
  return count
})

export const getFilteredItemCount = createSelector(getFilteredItems, (items) => {
  return items ? items.length : 0
})
