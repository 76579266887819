import React from 'react'
import Modal from '../../shared/components/Modal'
import { togglePreventScroll } from '../../shared/utility-functions'

export default class LinkQuestionnaire extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      step: 0,
      showModal: this.props.showModal
    }
    this.inner = this.inner.bind(this)
    this.close = this.close.bind(this)
    this.closeCheck = this.closeCheck.bind(this)
    this.continueHandler = this.continueHandler.bind(this)
    window.addEventListener('click', this.closeCheck, {passive: true})
  }

  componentWillUpdate (props, prev) {
    if (props.showModal && !prev.showModal) {
      this.state.showModal = true
      window.addEventListener('click', this.closeCheck, {passive: true})
    }
  }

  componentDidUpdate () {
    let firstLink = document.querySelector('.mychart-modal a')
    if (firstLink) {
      firstLink.focus()
      togglePreventScroll(true)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.closeCheck)
  }

  closeCheck (e) {
    if (e.target && e.target.className === 'background') {
      this.close()
    }
  }

  close () {
    togglePreventScroll()
    this.setState({ showModal: false })
    this.setState({ step: 0 })
    this.componentWillUnmount()
  }

  continueHandler (e) {
    let currentStep = this.state.step
    if (currentStep + 1 > this.props.links.length - 1) {
      this.close()
    } else {
      this.setState({ step: currentStep + 1 })
      e.preventDefault()
    }
  }

  inner () {
    let step = this.props.links[this.state.step]
    if (step) {
      return <div className='mychart-modal'>
        <div className='title'>{step.headline}</div>
        <p className='subheader'>{step.text}</p>
        {
          step.optionA.type === 'continue' &&
          <div><a tabIndex='0' className='continue-link' role='button' onKeyPress={(e) => { if (e.keyCode === 32) this.continueHandler(e) }} onClick={this.continueHandler}>{step.optionA.text}</a></div>
        }
        {
          step.optionA.type === 'link' &&
          <a tabIndex='0' className='btn btn-primary' role='link' target='_blank' href={step.optionA.link}>{step.optionA.text}</a>
        }
        {
          step.optionA.type === 'close' &&
          <a tabIndex='0' className='btn btn-primary' role='button' onKeyPress={(e) => { if (e.keyCode === 32) this.close(e) }} onClick={this.close}>{step.optionA.text}</a>
        }
        {
          step.optionB.type === 'link' &&
          <a tabIndex='0' className='btn btn-primary' target='_blank' role='link' href={step.optionB.link}>{step.optionB.text}</a>
        }

        {
          step.optionB.type === 'continue' &&
          <div><a tabIndex='0' className='continue-link' role='button' onKeyPress={(e) => { if (e.keyCode === 32) this.continueHandler(e) }} onClick={this.continueHandler}>{step.optionB.text}</a></div>
        }
        {
          step.optionB.type === 'close' &&
          <a tabIndex='0' className='btn btn-primary' role='button' onKeyPress={(e) => { if (e.keyCode === 32) this.close(e) }} onClick={this.close}>{step.optionB.text}</a>
        }
      </div>
    }
  }

  render () {
    return (<Modal hideFunction={this.close} showModal={this.state.showModal}>
      {this.props.links && this.props.links[this.state.step] && this.inner()}
    </Modal>)
  }
}
