const insuranceTransformer = (data) => {
  return data && data.length ? data.reduce((a, b) => {
    let found = a.find(plan => {
      return plan.provider === b.insuranceName && plan.plan === b.planName
    })
    if (found) {
      found.groups.push(b.groupName)
    } else {
      a.push({ plan: b.planName, provider: b.insuranceName, groups: [b.groupName] })
    }
    return a
  }, []) : []
}

export default insuranceTransformer
