import { connect } from 'react-redux'
import {getFilteredItemCount, getFilteredItems, getFilterCount} from './selectors/classes-selectors.js' // getFilterCount
import ClassesSearch from './ClassesSearch.jsx'
import { getURLParameters } from '../../shared/utility-functions/searchParams.js'
import { insertBrowserHistory } from '../../Search/state/history.js'

const mapStateToProps = (state, ownProps) => {
  return ({
    headline: ownProps.headline,
    searchItems: getFilteredItems(state),
    searchItemCount: getFilteredItemCount(state),
    dataLoaded: state.dataLoaded,
    searched: state.searched,
    urlParameters: getURLParameters(),
    filterOptions: ownProps.filterOptions,
    searchOptions: ownProps.searchOptions,
    filters: state.filters,
    enableUrl: ownProps.searchOptions.enableUrl,
    isBlock: ownProps.isBlock,
    defaultQuery: ownProps.searchOptions.defaultQuery,
    filterCount: getFilterCount(state),
    fetchCallFailed: state.callFailed
  })
}

const mapDispatchToProps = (dispatch) => ({
  runSearch: (params) => dispatch({
    type: 'GET_CLASSES',
    params: params
  }),
  getPredictiveClasses: (params) => dispatch({
    type: 'GET_CLASSES_AUTOCOMPLETE_DATA'
  }),
  setFilters: (value) => dispatch({
    type: 'SET_FILTERS',
    value
  }),
  resetFilters: () => dispatch({
    type: 'RESET_FILTERS'
  }),
  setPageNumber: (number) => dispatch({
    type: 'SET_CURRENT_PAGE',
    number
  })
})

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    initPage: () => {
      let params = getURLParameters()
      if (stateProps.defaultQuery) {
        params.search.q = stateProps.defaultQuery
      }
      dispatchProps.runSearch(params)
      dispatchProps.getPredictiveClasses()
      if (stateProps.enableUrl) {
        insertBrowserHistory(params, params.pageNumber)
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ClassesSearch)
