const insuranceSorter = (filteredInsurancePlans, sortOrder) => {
  switch (sortOrder) {
    case 'A TO Z':
      return filteredInsurancePlans.sort((a, b) => {
        if (a.provider && b.provider) {
          if (a.provider.toUpperCase() > b.provider.toUpperCase()) {
            return 1
          } else if (a.provider.toUpperCase() < b.provider.toUpperCase()) {
            return -1
          } else {
            if (a.plan.toUpperCase() > b.plan.toUpperCase()) {
              return 1
            } else {
              return -1
            }
          }
        } else {
          return -1
        }
      }).map(a => a)
    case 'Z TO A':
      return filteredInsurancePlans.sort((a, b) => {
        if (a.provider && b.provider) {
          if (a.provider.toUpperCase() > b.provider.toUpperCase()) {
            return -1
          } else if (a.provider.toUpperCase() < b.provider.toUpperCase()) {
            return 1
          } else {
            if (a.plan.toUpperCase() > b.plan.toUpperCase()) {
              return -1
            } else {
              return 1
            }
          }
        } else {
          return -1
        }
      }).map(a => a)
    default:
      return filteredInsurancePlans
  }
}

export default insuranceSorter
