import { race, put, take, delay } from 'redux-saga/effects'
import {fetchAPIData} from '../../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../../shared/constants/wait-times'
import { INSURANCE_ENDPOINT } from '../../../shared/constants/api-helpers.js'
import insuranceTransformer from '../../../shared/data-mappers/insurance-transformer.js'

export default function * insuranceSearchSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_INSURANCE')
    let params = action.params ? Object.assign({}, action.params) : {}
    params.endPoint = INSURANCE_ENDPOINT
    yield put({ type: 'SEARCH_INSURANCE' })
    yield put({ type: 'CLEAR_ITEMS' })
    yield put({ type: 'SET_CALL_FAILED', value: false })

    const { posts } = yield race({
      posts: fetchAPIData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.length > 0) {
      yield put({ type: 'SET_ITEMS', value: insuranceTransformer(posts) })
    } else {
      yield put({ type: 'SET_CALL_FAILED', value: true })
    }
  }
}
