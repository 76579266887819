import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import {classesRoot, locationRoot, serviceRoot, doctorRoot} from '../Search/state/root-reducer'
import autocompleteSaga from '../Search/sagas/fetch-autocomplete-data-saga'
import autocompleteLocationSaga from '../Search/sagas/fetch-locations-autocomplete-data-saga'
import autocompleteServiceSaga from '../Search/sagas/fetch-services-autocomplete-data-saga'
import autocompleteClassesSaga from '../shared/saga/fetch-classes-autocomplete-data-saga'
import LandingPageConnector from './LandingPageConnector.jsx'

export default class FADRoot extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()

    if (props.type === 'doctors') {
      this.fadStore = createStore(doctorRoot, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
      sagaMiddleware.run(autocompleteSaga)
    } else if (props.type === 'locations') {
      this.fadStore = createStore(locationRoot, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
      sagaMiddleware.run(autocompleteLocationSaga)
    } else if (props.type === 'services') {
      this.fadStore = createStore(serviceRoot, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
      sagaMiddleware.run(autocompleteServiceSaga)
    } else if (props.type === 'classes') {
      this.fadStore = createStore(classesRoot, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
      sagaMiddleware.run(autocompleteClassesSaga)
    }
  }

  render () {
    return (
      <Provider store={this.fadStore}>
        <LandingPageConnector type={this.props.type} title={this.props.title} displayMessage={this.props.displayMessage} categories={this.props.categories} />
      </Provider>
    )
  }
}
