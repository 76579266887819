import URLS from '../../../shared/constants/urls'
import { MAMMO_VISIT_REGULAR, MAMMO_VISIT_IMPLANTS, MAMMO_VISIT_ASSISTANCE } from '../../../shared/constants/api-helpers'

let currentQuestion = 1

const getCurrentQuestion = (age) => {
  let question = questions.find(q => q.id === currentQuestion)
  if (!question) {
    return 'done'
  } else if (question.id === 1) {
    question.answers[1].set = age < 51 ? 2 : 4
  }
  question.number = setNumber(age, question.id)
  return question
}

const setNumber = (age, id) => {
  if ((id >= questions.length - 2) && age >= 51) {
    return id - 2
  }
  return id
}

const answerQuestion = (action) => {
  if (typeof action.set === 'string') {
    window.sessionStorage.setItem('VisitTypeID', action.set)
    currentQuestion += 1
  } else {
    currentQuestion = action.set
  }
  if (currentQuestion === questions.length + 1) {
    let event = document.createEvent('Event')
    event.initEvent('questionnaireCompleted', true, true)
    window.dispatchEvent(event)
  }
}

export const mammogramGeneralCannotScheduleNotice = {
  text: 'Additional details are needed before scheduling your mammogram. Please contact your provider’s office.',
  url: URLS.findADoctorUrl,
  urlTitle: 'Find a Doctor'
}

export const mammogramDiagnosticOrderCannotScheduleNotice = {
  text: `We need to ask you a few questions about your physician’s order. Call your preferred OhioHealth mammography location for help scheduling.`,
  url: '/locations/mammography/',
  urlTitle: 'View Mammography Locations'
}

const questions = [
  {
    id: 1,
    question: 'Do you have an order from your provider for a diagnostic mammogram?',
    answers: [{ text: 'yes', error: mammogramDiagnosticOrderCannotScheduleNotice }, { text: 'no', set: 2 }]
  },
  {
    id: 2,
    question: 'Are you currently pregnant?',
    answers: [{ text: 'yes', error: mammogramGeneralCannotScheduleNotice }, { text: 'no', set: 3 }]
  },
  {
    id: 3,
    question: 'Are you currently breastfeeding, or have breastfed within the last six months?',
    answers: [{ text: 'yes', error: mammogramGeneralCannotScheduleNotice }, { text: 'no', set: 4 }]
  },
  {
    id: 4,
    question: 'Do you have any new problems or complaints with either breast?',
    answers: [{ text: 'yes', error: mammogramGeneralCannotScheduleNotice }, { text: 'no', set: 5 }]
  },
  {
    id: 5,
    question: 'Do you have breast implants?',
    answers: [{ text: 'yes', set: MAMMO_VISIT_IMPLANTS }, { text: 'no', set: MAMMO_VISIT_REGULAR }]
  },
  {
    id: 6,
    question: 'Do you need special assistance or require mobility devices, such as a wheelchair or walker?',
    answers: [{ text: 'yes', set: MAMMO_VISIT_ASSISTANCE }, { text: 'no', set: 7 }]
  }
]

const MammService = (age) => {
  currentQuestion = 1
  return {
    getCurrentQuestion: () => { return getCurrentQuestion(age) },
    numberOfQuestions: age < 51 ? 6 : 4,
    answerQuestion
  }
}

export default MammService
