import React from 'react'
import LoadingElement from '../../shared/components/LoadingElement'
import { fetchData } from '../../shared/services/fetchData'
import SVGInline from 'react-svg-inline'
import locationIcon from '../../shared/assets/location.svg'
import doctorIcon from '../../shared/assets/doctor.svg'
import ArrowRight from '../../shared/assets/arrow-right.svg'
import serviceIcon from '../../shared/assets/service.svg'
import './find-results.scss'
import { LOCATION_SEARCH_ENDPOINT, FAD_PROVIDERS_ENDPOINT, SERVICES_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default class FindResults extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    if (this.props.type) {
      let params = {}
      switch (this.props.type) {
        case 'Doctor':
          params.endPoint = FAD_PROVIDERS_ENDPOINT
          params.q = this.props.query
          this.state = {
            href: `/find-a-doctor/results?q=${this.props.query}`,
            call: fetchData,
            icon: doctorIcon,
            params: params
          }
          break
        case 'Location':
          params.endPoint = LOCATION_SEARCH_ENDPOINT
          params.q = this.props.query
          this.state = {
            href: `/locations/results?q=${this.props.query}`,
            call: fetchData,
            icon: locationIcon,
            params: params
          }
          break
        case 'Service':
          params.q = this.props.query
          params.endPoint = SERVICES_ENDPOINT
          this.state = {
            href: `/services/results?q=${this.props.query}`,
            call: fetchData,
            icon: serviceIcon,
            params: params
          }
          break
        default:
          this.state = {
            icon: doctorIcon
          }
          break
      }
    }

    this.runSearch = this.runSearch.bind(this)
    if (this.state.call) {
      this.runSearch()
    }
  }

  runSearch () {
    if (this.props.query) {
      this.state.call(this.state.params).then((data) => {
        if (data && data.success) {
          this.setState({ results: data.results, dataLoaded: true })
        }
      })
    }
  }

  render () {
    return this.props.type ? <div className={`found-results-container ${this.props.type}-found-results`}>
      <div className='found-results show-for-medium'>
        <div className='icon-wrap results-icon'><SVGInline className='icon' svg={this.state.icon} /></div>
        <div className='results-number'>
          <strong >
            {this.state.dataLoaded && this.state.results && this.state.results.length}
            {!this.state.dataLoaded && <LoadingElement />}
          </strong>
        </div>
        <div className='results-description'>{`${this.props.type}${(this.state.results && this.state.results.length !== 1) || !this.state.results ? 's' : ''}`} Found</div>
        <a href={this.state.href} target='_self' className='results-btn btn'>View {this.props.type}s</a>
      </div>
      <a className='hide-for-medium found-results' href={this.state.href} target='_self'>
        <SVGInline className='icon' svg={this.state.icon} />
        <div className='results-number'>
          <strong>
            <span className='results-digit'>{this.state.dataLoaded && this.state.results && this.state.results.length}</span>
            {!this.state.dataLoaded && <LoadingElement />}
          </strong>
        </div>
        <div className='results-description'>{`${this.props.type}${(this.state.results && this.state.results.length !== 1) || !this.state.results ? 's' : ''}`} Found</div>
        <SVGInline className='results-arrow' svg={ArrowRight} />
      </a>
    </div> : null
  }
}
