import React from 'react'
import FindResults from './FindResults'
import { getParameterByName } from '../shared/utility-functions/searchParams'
import SVGInline from 'react-svg-inline'
import ArrowRight from '../shared/assets/arrow-right.svg'
import LoadingElement from '../shared/components/LoadingElement'
import loadsData from '../shared/components/LoadsData'
import { fetchData } from '../shared/services/fetchData'
import { SITE_SEARCH_ENDPOINT } from '../shared/constants/api-helpers.js'
import sanitizeHtmlReact from 'sanitize-html-react'
import BackToTop from '../shared/components/BackToTop'
import './site-search.scss'

export default class SiteSearch extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dataLoaded: false,
      query: getParameterByName('q'),
      filter: getParameterByName('f') || 'site',
      page: getParameterByName('s') || 0,
      perPage: getParameterByName('t') || 10
    }

    this.runSearch = this.runSearch.bind(this)
    this.results = this.results.bind(this)
    this.updateFilter = this.updateFilter.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.runSearch()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      this.runSearch()
    }
  }

  async runSearch () {
    if (this.state.query) {
      if (!this.state.filter) this.state.filter = 'site'
      let totalCount = (Number(this.state.page) + 1) * this.state.perPage
      fetchData({ endPoint: SITE_SEARCH_ENDPOINT, q: this.state.query, f: this.state.filter, s: 0, t: totalCount }).then((data) => {
        if (data && data.success) {
          this.setState({ results: data.results, total: data.total, dataLoaded: true })
        }
      })
    }
  }

  updateFilter (event) {
    if (event.target && event.target.value && this.state.filter !== event.target.value) {
      let params, newState
      if (event.target.value === 'all') {
        params = `?q=${this.state.query}&s=0&t=10&type=site`
        newState = { page: 0, perPage: 10, filter: null, total: null, dataLoaded: false }
      } else {
        params = `?q=${this.state.query}&f=${event.target.value}&s=0&t=10&type=site`
        newState = { filter: event.target.value, page: 0, perPage: 10, total: null, dataLoaded: false }
      }
      window.history.pushState(null, null, (window.location.pathname + encodeURI(params)))
      this.setState(newState)
    }
  }

  loadMore () {
    this.setState({ loadingMore: true })
    let nextPage = Number(this.state.page) + 1
    let newData = this.state.results
    fetchData({ endPoint: SITE_SEARCH_ENDPOINT, q: this.state.query, f: this.state.filter, s: nextPage * this.state.perPage, t: this.state.perPage }).then((data) => {
      if (data.success) {
        data.results.forEach(d => newData.push(d))
        this.setState({ results: newData, page: nextPage, loadingMore: false })
        window.history.pushState(null, null, (window.location.pathname + encodeURI(`?q=${this.state.query}${this.state.filter ? '&f=' : ''}${this.state.filter ? this.state.filter : ''}&s=${this.state.page}&t=${this.state.perPage}&type=site`)))
      }
    })
  }

  results () {
    return this.state.results && this.state.results.length > 0 ? this.state.results.map((result, index) => {
      return <div className='results-item' key={index}>
        <a href={result.Url} className='item-link'>
          {
            result.ImageUri != null &&
            <img src={result.ImageUri} className='item-thumb' border='0' />
          }
          <h5 className='item-title' dangerouslySetInnerHTML={{ '__html': result.Title }} />
          <div className='item-copy'>
            <div dangerouslySetInnerHTML={{ '__html': sanitizeHtmlReact(sanitizeHtmlReact(result.Excerpt, { allowedTags: ['em'] }), { allowedTags: ['em'] }) }} />
            <div className='faux-a item-url'>{result.Url.split('?')[0]}</div>
          </div>
        </a>
      </div>
    }) : <div><br /></div>
  }

  loadingContent () {
    return <div className='results-list'>
      <div className='results-item skeleton-box' />
      <div className='results-item skeleton-box' />
      <div className='results-item skeleton-box' />
      <div className='results-item skeleton-box' />
      <div className='results-item skeleton-box' />
      <div className='results-item skeleton-box' />
    </div>
  }

  render () {
    return <div className='site-search'>
      <h2 className='heading'>Search Results for <strong className='heading-term'>{this.state.query}</strong></h2>
      <div className='search-found'>
        <FindResults query={this.state.query} type='Doctor' />
        <FindResults query={this.state.query} type='Location' />
        <FindResults query={this.state.query} type='Service' />
      </div>
      <div className='search-results'>
        {this.state.dataLoaded &&
          <div className='results-heading'>
            <h4 className='results-count'>{this.state.results && this.state.results.length ? `Displaying ${this.state.results.length} of ` : ''}{this.state.total && this.state.total > 0 ? `${this.state.total} Page` : 'No'} Results</h4>
            <div className='select-dropdown'>
              <label for='filter-select'>Show Results For</label>
              <select id='filter-select' value={this.state.filter} onChange={this.updateFilter}>
                <option value='site'>Site</option>
                <option value='blog'>Blog</option>
                <option value='news'>News</option>
              </select>
            </div>
          </div>
        }
        {
          !this.state.dataLoaded && <div className='fake-headline' />
        }
        <div className='results-list'>
          {
            loadsData({
              dataLoaded: this.state.dataLoaded,
              dataFetchFailed: this.state.error,
              generateDataContent: this.results,
              generateLoadingContent: this.loadingContent,
              generateErrorContent: () => <div>{this.state.error}</div>
            })
          }
        </div>
        {this.state.results && this.state.results.length < this.state.total && <div className='show-more' onClick={() => { this.loadMore() }}><span>Show more {this.state.loadingMore ? <LoadingElement /> : <SVGInline svg={ArrowRight} />}</span></div>}
      </div>
      <BackToTop />
    </div>
  }
}
