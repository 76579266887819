import isEmpty from 'validator/lib/isEmpty'
import trim from 'validator/lib/trim'
import isBefore from 'validator/lib/isBefore'
import isAfter from 'validator/lib/isAfter'
import isNumeric from 'validator/lib/isNumeric'
import isLength from 'validator/lib/isLength'
import moment from 'moment'

const oldest = '1900-01-01'

const isDateOfBirthValid = (value = '') => {
  if (typeof value === 'string') {
    const trimmed = moment(trim(value), 'MM/DD/YYYY', true).format('YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD')
    return !isEmpty(trimmed) && isBefore(trimmed, today) && isAfter(trimmed, oldest)
  }

  if (isMonthValid(value.month) && isDayValid(value.day) && isYearValid(value.year)) {
    let day = convertAllTheSingleNumbers(value.day)
    let month = convertAllTheSingleNumbers(value.month)
    const today = moment().format('YYYY-MM-DD')
    const givenDate = moment(`${month}/${day}/${trim(value.year)}`, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
    return isBefore(givenDate, today) && isAfter(givenDate, oldest)
  } else { return false }
}

const isDateOfBirthAboveEighteen = (value = '') => {
  const eighteenYearsAgo = moment().subtract(18, 'years').format('YYYY-MM-DD')

  if (typeof value === 'string') {
    const trimmed = moment(trim(value), 'MM/DD/YYYY', true).format('YYYY-MM-DD')
    return !isEmpty(trimmed) && isBefore(trimmed, eighteenYearsAgo) && isAfter(trimmed, oldest)
  }

  if (isMonthValid(value.month) && isDayValid(value.day) && isYearValid(value.year)) {
    let day = convertAllTheSingleNumbers(value.day)
    let month = convertAllTheSingleNumbers(value.month)
    const givenDate = moment(`${month}/${day}/${trim(value.year)}`, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
    return isBefore(givenDate, eighteenYearsAgo) && isAfter(givenDate, oldest)
  } else { return false }
}

const isMonthValid = (value = '') => {
  const biggestMonth = 12
  const monthMaxLength = 2
  const trimmed = trim(value)
  return isNumeric(trimmed) && isLength(trimmed, { min: monthMaxLength - 1, max: monthMaxLength }) &&
    isBetween(trimmed, 1, biggestMonth)
}

const isDayValid = (value = '') => {
  const biggestDay = 31
  const dayMaxLength = 2
  const trimmed = trim(value)
  return isNumeric(trimmed) &&
    isLength(trimmed, { min: dayMaxLength - 1, max: dayMaxLength }) &&
    isBetween(trimmed, 1, biggestDay)
}

const isYearValid = (value = '') => {
  const smallestYear = 1900
  const currentYear = moment().year()
  const yearMaxLength = 4
  const trimmed = trim(value)
  return isNumeric(trimmed) &&
    isLength(trimmed, { min: yearMaxLength, max: yearMaxLength }) &&
    isBetween(trimmed, smallestYear, currentYear)
}

const isBetween = (value, min, max) => {
  const isAbove = min <= value
  const isBelow = value <= max
  return isAbove && isBelow
}

const convertAllTheSingleNumbers = (value) => {
  return ('0' + trim(value)).slice(-2)
}
export { isDateOfBirthValid, isMonthValid, isDayValid, isYearValid, isDateOfBirthAboveEighteen }
