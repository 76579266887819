import React from 'react'
import RightArrow from '../../../shared/assets/arrow-right.svg'
import SVGInline from 'react-svg-inline'

export default class ScoredQuestion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      shape: this.props.question.shape,
      type: this.props.question.type,
      showResult: false,
      answeredCorrectly: null
    }
    this.score = this.score.bind(this)
  }

  score (answer) {
    this.setState({ showResult: true, answeredCorrectly: answer.letter === this.props.question.correctAnswer })
  }

  reset () {
    this.setState({
      shape: this.props.question.shape,
      type: this.props.question.type,
      showResult: false,
      answeredCorrectly: null,
      clicked: null
    })
  }

  render () {
    return <div className='epi-block scored-question full slideIn' style='display: block;'><div className={`question-container scored ${this.props.question.image ? 'has-bg-image' : ''}`} style={`background-image: url('${this.props.question.image}');`}>
      <div className='question-number'>QUESTION {this.props.index + 1} OF <span className='questionCount'>{this.props.total}</span></div>
      <div className='question-title' dangerouslySetInnerHTML={{__html: this.props.question.text}} />
      <div className='clearfix'>&nbsp;</div>
      <div className={`answers-container ${this.state.type.toLowerCase() === 'text' ? 'text' : 'number'}`}>
        {
          this.props.question && this.props.question.answers && this.props.question.answers.length > 0 &&
        this.props.question.answers.map((answer, index) => {
          return <div key={index} className={`answer ${this.state.clicked === answer.letter ? 'clicked' : ''} ${answer.letter === this.props.question.correctAnswer ? 'correct' : 'wrong'}`} onClick={() => { if (!this.state.showResult) { this.score(answer); this.setState({ clicked: answer.letter }) } }}>
            <div className='label'>
              <div className={`${this.state.type.toLowerCase() === 'image' ? 'image' : ''} border-${this.state.shape.toLowerCase() === 'square' ? 'square' : 'circle'}`} style={`background-image:url('${answer.image}');`}>
                <span>{this.state.type.toLowerCase() !== 'image' && answer.copy}</span>
              </div>
              { answer.imageCaption && <span className='image-caption'>{answer.imageCaption}</span> }
              <span className='option-letter'>{answer.letter}</span>
            </div>
          </div>
        })
        }
      </div>
      <div className='clearfix'>&nbsp;</div>
      {
        this.state.showResult &&
        <div className='results-block'>
          <div className='results-container'>
            <div className='results-body'>
              {this.state.answeredCorrectly && <div className='results-title correct'>Great work! You got it right.</div>}
              {!this.state.answeredCorrectly && <div className='results-title incorrect'>The correct answer is: {this.props.question.correctAnswer}</div>}
              <div className='results-message' dangerouslySetInnerHTML={{__html: this.props.question.answerArea}} />
              <div className='disclaimer' dangerouslySetInnerHTML={{__html: this.props.question.disclaimerText}} />
            </div>
            <div className='results-footer'>
              <div className='next-question'>
                <div className='number-of'><span className='questionIndex'>{this.props.index + 1}</span> / <span className='questionCount'>{this.props.total}</span></div>
                <button className='next-btn' onClick={() => { this.props.clickHandler(this.state.answeredCorrectly ? 1 : 0); this.reset() }}>
                  <span className='btn-text'>NEXT</span>
                  <SVGInline className='next-arrow' svg={RightArrow} />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
    </div>
  }
}
