import React from 'react'
// import closeIcon from '../../shared/assets/close-icon.svg'
// import SVGInline from 'react-svg-inline'
import ErrorMessageBar from '../../shared/components/AlertBar/ErrorMessageBar'
// import loadsData from '../../shared/components/LoadsData'
import SearchItemResults from '../../Search/components/SearchItemResults'
import { SkeletonInsuranceCard } from './InsuranceCard/'
// import { PATIENT_ERROR_MESSAGE } from '../../shared/constants/messages'
// import BackToTop from '../../shared/components/BackToTop'
import { insertBrowserHistory } from '../../Search/state/history'
// import SearchSortConnector from './InsuranceSort/InsuranceSortConnector.jsx'
import { scrollToLocation, insertEmptyFilters, getURLParameters, getParameterByName } from '../../shared/utility-functions'
// import InsuranceFilters from './InsuranceFilters/InsuranceFilters.jsx'
import './insurance.scss'

export default class InsuranceSearch extends React.Component {
  constructor (props) {
    super(props)
    this.props.initPage()
    this.state = {
      asideClass: null,
      desktopFixed: false,
      desktopBottom: false
    }

    this.clearAllFilters = this.clearAllFilters.bind(this)
    this.setFiltersFromURL = this.setFiltersFromURL.bind(this)
    this.generateDataContent = this.generateDataContent.bind(this)
    this.generateErrorContent = this.generateErrorContent.bind(this)
    this.generateLoadingContent = this.generateLoadingContent.bind(this)
    this.handleStickyElements = this.handleStickyElements.bind(this)
    this.urlParameters = this.props.urlParameters || { filters: {} }
    this.changeDesktopState = this.changeDesktopState.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleStickyElements, {passive: true})
    this.setFiltersFromURL()
    window.addEventListener('popstate', this.setFiltersFromURL, {passive: true})
  }

  setFiltersFromURL () {
    let tempParams = getURLParameters()
    let overrideSort = this.props.searchOptions ? this.props.searchOptions.defaultSort : null
    this.props.setFilters(tempParams.filters)
    let sortBy = getParameterByName('sort')
    if (sortBy) {
      this.props.setSort(tempParams.sort)
    } else if (overrideSort) {
      this.props.setSort(overrideSort)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleStickyElements)
    window.removeEventListener('popstate', this.setFiltersFromURL)
  }

  handleStickyElements (e) {
    let bufferNumber = -20
    let boundings = document.getElementById('doctor-search-container') ? document.getElementById('doctor-search-container').getBoundingClientRect() : {}
    let filterBottom = document.getElementById('provider-filter-container') ? document.getElementById('provider-filter-container').getBoundingClientRect().bottom : {}
    let filterTop = document.getElementById('provider-filter-container') ? document.getElementById('provider-filter-container').getBoundingClientRect().top : {}
    let footerTop = document.getElementsByClassName('footer')[0] ? document.getElementsByClassName('footer')[0].getBoundingClientRect().top : null
    let stickyHeaderBottom = document.getElementsByClassName('fixed-filter-sort-header')[0] ? document.getElementsByClassName('fixed-filter-sort-header')[0].getBoundingClientRect().bottom : null

    if (boundings.bottom < bufferNumber && this.state.desktopFixed !== true && footerTop > 80) {
      this.setState({ desktopFixed: true })
    } else if (boundings.bottom > bufferNumber && this.state.desktopFixed !== false) {
      this.setState({ desktopFixed: false })
    } else if (stickyHeaderBottom !== null && footerTop < 80) {
      this.setState({ desktopFixed: false })
    }

    if (this.state.desktopBottom === false && filterBottom + 35 > footerTop && footerTop - 35 < window.innerHeight) {
      this.setState({ desktopBottom: true })
    } else if (this.state.desktopBottom === true && (footerTop - 35 > window.innerHeight || Math.sign(filterTop - 62) === 1)) {
      this.setState({ desktopBottom: false })
    }
  }

  clearAllFilters (e) {
    e.stopPropagation()
    scrollToLocation('.find-a-doctor-page')
    insertBrowserHistory(insertEmptyFilters(getURLParameters()))
    this.props.resetFilters()
  }

  changeDesktopState (toggleFixed, toggleBottom) {
    this.setState({
      desktopFixed: toggleFixed,
      desktopBottom: toggleBottom
    })
  }

  generateDataContent () {
    return <SearchItemResults flow='insurance' handleStickyElements={this.handleStickyElements}
      changeDesktopState={this.changeDesktopState}
      searchItemsPageGroup={this.props.searchItems}
      setPageNumber={this.props.setPageNumber} />
  }

  generateLoadingContent () {
    return <div className='schedule-a-doctor-provider-results'>
      <div className='service-box'><SkeletonInsuranceCard key={1} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={2} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={3} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={4} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={5} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={6} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={7} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={8} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={9} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={10} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={11} /></div>
      <div className='service-box'><SkeletonInsuranceCard key={12} /></div>
    </div>
  }

  generateErrorContent (errorMessage, fetchRetry) {
    return (
      <div className='component-container'>
        <div className='message-bar-container'>
          <ErrorMessageBar text={errorMessage} retryHandler={fetchRetry} />
        </div>
      </div>
    )
  }

  render () {
    // return (
    // <div className={`find-a-doctor-page insurance-search`}>
    //   {this.props.headline && this.props.headline.length && <h1 className={`block-header page-header`}>{this.props.headline}</h1>}
    //   <div className={`hide-for-large filters-aside ${this.state.asideClass}`}>
    //     <div className='mobile-filter-title-container'>
    //       <SVGInline className='close-filters' svg={closeIcon} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }} />
    //       <div className='mobile-filter-title'>Filter</div>
    //       {this.props.filterCount > 0 && <div className='mobile-filter-clear' onClick={(e) => this.clearAllFilters(e)}>Clear All</div>}
    //     </div>
    //     <div className='mobile-filters-container'>
    //       <InsuranceFilters urlParameters={this.urlParameters.filters} dataLoaded={this.props.dataLoaded} mobile />
    //     </div>
    //     <button className='aside-btn' tabIndex='0' onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }}>{!this.props.dataLoaded ? 'LOADING' : `SEE ${this.props.searchItemCount}`} RESULTS</button>
    //   </div>
    //   {
    //     this.props.dataLoaded && this.props.searchItemCount === 0 && !this.props.fetchCallFailed
    //       ? <div className='fad-error-container no-results-message'>
    //         <h4>No Results Found</h4>
    //       </div>
    //       : <div>
    //         <div id='filter-button-row-header' className={this.state.desktopFixed ? 'row-container fixed-filter-sort-header' : 'row-container button-row'}>
    //           <div className='filters-container show-for-large'>
    //             <div className={`filter-header-label ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}><span>Filters</span>
    //               {this.props.filterCount > 0 && <div className='clear-all-filters' onClick={(e) => this.clearAllFilters(e)}>Clear All</div>}
    //             </div>
    //           </div>
    //           <div className={`sort-main-container ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}>
    //             <button onClick={() => { togglePreventScroll(true); this.setState({ asideClass: 'slide-in' }) }} className='hide-for-large btn filter-btn'>Filter</button>
    //             <div className={`sort-container sort-border ${this.props.dataLoaded && this.props.searchItems && this.props.searchItems.length <= 1 ? 'disabled' : ''}`}>
    //               <SearchSortConnector enableUrl flowType={this.props.flow} />
    //             </div>
    //           </div>
    //         </div>
    //         <div className='row-container content'>
    //           <div className={`filters-content show-for-large ${this.state.desktopFixed ? (this.state.desktopBottom ? 'filters-content-absolute' : 'filters-content-fixed') : ''}`}>
    //             <div id='provider-filter-container' className='filters-inner'>
    //               <div className='filter-group-container'>
    //                 <InsuranceFilters urlParameters={this.urlParameters.filters} dataLoaded={this.props.dataLoaded} />
    //               </div>
    //             </div>
    //           </div>
    //           <div className={`${this.state.desktopFixed ? 'results-container-fixed' : ''} ${this.props.sort ? this.props.sort.toLowerCase().replace(/\s/g, '-') : ''} results-container`}>
    //             {
    //               loadsData({
    //                 dataLoaded: !this.props.searched || this.props.dataLoaded,
    //                 dataFetchFailed: this.props.fetchCallFailed,
    //                 generateDataContent: this.generateDataContent,
    //                 generateLoadingContent: this.generateLoadingContent,
    //                 generateErrorContent: () => this.generateErrorContent(PATIENT_ERROR_MESSAGE, this.props.runSearch)
    //               })
    //             }
    //           </div>
    //         </div>
    //       </div>
    //   }
    //   <BackToTop />
    // </div>
    // )
  }
}
