import { connect } from 'react-redux'
import LookingFor from './LookingFor.jsx'

const mapStateToProps = (state, ownProps) => ({
  setLookingFor: ownProps.setLookingFor,
  lookingFor: ownProps.lookingFor,
  autoCompleteData: state.classesSearch ? state.classesSearch.autoCompleteDataClasses : state.autoCompleteDataClasses,
  placeholder: 'Enter class name or category',
  enterSubmit: ownProps.enterSubmit,
  isMobile: ownProps.mobile
})

export default connect(mapStateToProps)(LookingFor)
