import { race, put, take, delay } from 'redux-saga/effects'
import { fetchData } from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { CLASSES_AUTOCOMPLETE_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default function * autocompleteClassesSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_CLASSES_AUTOCOMPLETE_DATA')
    let params = {}
    params.ids = action.ids ? action.ids : ''
    params.endPoint = CLASSES_AUTOCOMPLETE_ENDPOINT
    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts) {
      yield put({ type: 'SET_CLASSES_AUTOCOMPLETE_DATA', value: posts.results ? { classesKeywords: posts.results.Keywords ? cleanData(posts.results.Keywords) : null, classesNames: posts.results.Names ? cleanData(posts.results.Names) : null } : null })
    }
  }
}

const cleanData = (data) => {
  return data.map(item => {
    return item.replace('Â', '').replace('â„¢', '™')
  })
}
