import React, { useState, useEffect } from 'react'
import './group-chip.scss'

const GroupChip = ({ conditionGroup, clickHandler, reasonForVisitGroup }) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (reasonForVisitGroup.ID === conditionGroup.ID && !selected) {
      setSelected(true)
    } else if (reasonForVisitGroup.ID !== conditionGroup.ID && selected) {
      setSelected(false)
    }
  })

  return <button className={`chip${selected ? ' selected' : ''}`} onClick={() => { clickHandler(conditionGroup.ID) }}>{conditionGroup.DisplayName}</button>
}

export default GroupChip
