import React from 'react'
import Modal from '../../shared/components/Modal'
import SVGInline from 'react-svg-inline'
import ErrorModal from './ErrorModal'
import UCReasonForVisitModalConnector from './UCReasonForVisitModal/UCReasonForVisitModalConnector.jsx'
import MyChartModal from './MyChartModal'
import checkmarkInCircle from '../../shared/assets/checkmark-in-circle.svg'
import { togglePreventScroll } from '../../shared/utility-functions'
import URLS from '../../shared/constants/urls'

export default class UrgentCareQuestionnaire extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isEligibleForVideoVisit: null
    }
    this.inner = this.inner.bind(this)
    this.close = this.close.bind(this)
    this.setElig = this.setElig.bind(this)
    this.hideFunction = this.hideFunction.bind(this)
    this.handleMyChartSubmission = this.handleMyChartSubmission.bind(this)
    this.handleReasonForVisitSubmission = this.handleReasonForVisitSubmission.bind(this)
  }

  componentDidUpdate () {
    let firstLink = document.querySelector('.uc-disclaimer a')
    if (firstLink) {
      firstLink.focus()
      togglePreventScroll(true)
    }
  }

  handleMyChartSubmission () {
    this.props.setModalStep(2)
  }

  handleReasonForVisitSubmission () {
    this.close()
  }

  close () {
    togglePreventScroll()
    this.props.setShowModal(false)
  }

  setElig () {
    window.sessionStorage.setItem('UCEligible', 'true')
    this.close()
    this.props.handleVideoVisitEligibilityConfirmation()
  }

  hideFunction () {
    if (this.state.isEligibleForVideoVisit === null) {
      window.location.href = URLS.urgentCareGateway
    } else {
      this.close()
    }
  }

  inner () {
    if (!this.state.error) {
      switch (this.props.modalStep) {
        case 1:
          return <MyChartModal submissionHandler={this.handleMyChartSubmission} />
        case 2:
          return <UCReasonForVisitModalConnector handleSubmission={this.handleReasonForVisitSubmission} setEligibility={(isEligible) => { this.setState({ isEligibleForVideoVisit: isEligible }) }} />
        case 3:
          return <div className='uc-disclaimer'>
            <div className='title'>Be sure you’re eligible for a video visit.</div>
            <ul>
              <li><SVGInline svg={checkmarkInCircle} /> 18 or older.</li>
              <li><SVGInline svg={checkmarkInCircle} /> Must be in the state of Ohio at the time of your visit.</li>
              <li><SVGInline svg={checkmarkInCircle} /> Must not be an unresolved problem from a prior urgent care video visit.</li>
            </ul>
            <button className='btn' onClick={this.setElig}>I'm Eligible</button>
          </div>
        default:
          return <div />
      }
    } else {
      return <ErrorModal error={this.state.error} />
    }
  }

  render () {
    return (<Modal hideFunction={this.hideFunction} showModal={this.props.showModal}>
      {this.inner()}
    </Modal>)
  }
}
