import React from 'react'
import SVGInline from 'react-svg-inline'
import ScheduleADoctorProviderCardConnector from '../SearchCards/ProviderCard/ScheduleADoctorProviderCardConnector.jsx'
import LocationCardConnector from '../SearchCards/LocationCard/LocationCardConnector.jsx'
import ServiceCard from '../SearchCards/ServiceCard/ServiceCard.jsx'
import {InsuranceCard} from '../../../Blocks/InsuranceSearch/InsuranceCard'
import AffiliatedProviderCard from '../SearchCards/AffiliatedProviderCard'
import { getPageNumber, getID } from '../../../shared/utility-functions/searchParams.js'
import { getOffSetY } from '../../../shared/utility-functions/offSet'
import ArrowRight from '../../../shared/assets/arrow-right.svg'
import './search-item-results.scss'
import { ClassesCard } from '../../../Blocks/Classes/ClassesCard/index.js'

const PAGE_SIZE = 12

export default class SearchItemResults extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      hasMore: false,
      shownResults: [],
      loaded: false
    }
    this.loadMoreItems = this.loadMoreItems.bind(this)
  }
  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.props.searchItemsPageGroup) !== JSON.stringify(prevProps.searchItemsPageGroup)) {
      this.initialLoad()
    }
    if (prevState.loaded !== this.state.loaded && this.props.sort !== 'Random') {
      let npi = getID()
      if (npi) {
        let selectedDoctor = document.getElementById(`provider-card-${npi}`)
        if (selectedDoctor && getOffSetY(selectedDoctor) > window.innerHeight) {
          selectedDoctor.scrollIntoView()
          let position = window.scrollY
          window.scrollTo(0, position - 100)
        }
      }
    }
  }

  componentDidMount () {
    if (this.state.shownResults.length === 0 && this.props.searchItemsPageGroup.length > 0) {
      this.initialLoad()
    }
  }

  initialLoad () {
    let hasMore = true
    let newResults = []
    let pageNumberFromUrl = getPageNumber() ? parseInt(getPageNumber(), 10) : null
    let cardsToRender = pageNumberFromUrl ? pageNumberFromUrl * PAGE_SIZE : PAGE_SIZE

    if (cardsToRender > this.props.searchItemsPageGroup.length) {
      cardsToRender = this.props.searchItemsPageGroup.length
      pageNumberFromUrl = Math.ceil(this.props.searchItemsPageGroup.length / PAGE_SIZE)
    }

    if (this.props.searchItemsPageGroup.length < PAGE_SIZE) {
      cardsToRender = this.props.searchItemsPageGroup.length
      hasMore = false
    }

    for (let i = 0; i < cardsToRender; i++) {
      const addObject = JSON.parse(JSON.stringify(this.props.searchItemsPageGroup[i]))
      newResults.push(addObject)
    }

    if (pageNumberFromUrl) {
      this.setState({
        page: pageNumberFromUrl,
        hasMore: this.props.searchItemsPageGroup.length > (pageNumberFromUrl * PAGE_SIZE),
        shownResults: newResults,
        loaded: true
      })
      this.props.setPageNumber(pageNumberFromUrl)
    } else {
      this.setState({
        page: 1,
        hasMore: hasMore,
        shownResults: newResults,
        loaded: true
      })
      this.props.setPageNumber(1)
    }
  }

  results (searchItemsPageGroup) {
    let searchItemCards = []
    if (searchItemsPageGroup.length >= 1) {
      if (this.props.flow === 'location' || this.props.flow === 'mam') {
        searchItemCards = searchItemsPageGroup.map((location, index) =>
          <div className='provider-box' key={index}>
            <LocationCardConnector
              ref={`location-card-${location.Id}`}
              options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
              location={location} />
          </div>)
      } else if (this.props.flow === 'service') {
        searchItemCards = searchItemsPageGroup.map((service, index) => {
          return <div className='service-box' key={index}><ServiceCard service={service} /></div>
        })
      } else if (this.props.flow === 'fad' || this.props.flow === 'sad' || this.props.flow === 'cin') {
        searchItemCards = searchItemsPageGroup.map((provider, index) =>
          <div className='provider-box' key={index}>
            <ScheduleADoctorProviderCardConnector
              id={`provider-card-${provider.npi}`}
              options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
              provider={provider} />
          </div>)
      } else if (this.props.flow === 'insurance') {
        searchItemCards = searchItemsPageGroup.map((plan, index) =>
          <div className='provider-box' key={index}>
            <InsuranceCard id={`provider-card-${index}`} plan={plan} />
          </div>)
      } else if (this.props.flow === 'classes') {
        searchItemCards = searchItemsPageGroup.map((classes, index) =>
          <div className='provider-box' key={index}>
            <ClassesCard id={`provider-card-${index}`} class={classes} />
          </div>)
      }
    }
    if (!this.state.hasMore && this.props.showAffiliatedProviderCard) {
      searchItemCards.push(<div className='provider-box' key={searchItemsPageGroup.length}><AffiliatedProviderCard enableAffiliatedDoctors={this.props.toggleAffiliatedDoctors} /></div>)
    }
    return searchItemCards
  }

  loadMoreItems () {
    let nextPage = this.state.page + 1
    let hasMore = true
    let cardsToRender = PAGE_SIZE
    let pagedCards = PAGE_SIZE * nextPage
    let firstCard = (PAGE_SIZE * (this.state.page))
    let endIndex = 0
    let newResults = this.state.shownResults
    let totalCards = this.props.searchItemsPageGroup.length

    this.props.changeDesktopState(false, false)
    if (this.state.shownResults.length !== 0 && totalCards > 0) {
      if (totalCards <= pagedCards) {
        cardsToRender = (totalCards - firstCard)
        hasMore = false
      }
      endIndex = firstCard + cardsToRender
      for (let i = firstCard; i < endIndex; i++) {
        const addObject = JSON.parse(JSON.stringify(this.props.searchItemsPageGroup[i]))
        newResults = newResults.concat(addObject)
      }
      this.setState({
        page: nextPage,
        shownResults: newResults,
        hasMore: hasMore,
        loaded: true
      })
      this.props.setPageNumber(nextPage)
    }
    this.props.handleStickyElements()
  }

  render () {
    return (
      <div className={this.props.isBlock ? 'doctor-search-provider-results-block' : 'doctor-search-provider-results'}>
        <div className='loaded-results-container'>
          {this.results(this.state.shownResults)}
          {this.state.hasMore && <div className='show-more' onClick={() => { this.loadMoreItems() }}><span>Show more <SVGInline svg={ArrowRight} /></span></div>}
        </div>
      </div>
    )
  }
}
