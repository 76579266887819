export const DEFAULT_STATE = {
  items: [],
  searched: false,
  dataLoaded: false,
  callFailed: false,
  filters: [],
  sort: 'A TO Z',
  currentPage: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, ...{ items: action.value, dataLoaded: true } }
    case 'CLEAR_ITEMS':
      return { ...state, ...{ items: DEFAULT_STATE.items, dataLoaded: false } }
    case 'SEARCH_INSURANCE':
      return { ...state, ...{ searched: true } }
    case 'SET_CALL_FAILED':
      return { ...state, ...{ callFailed: action.value } }
    case 'SET_SORT':
      return { ...state, ...{ sort: action.value } }
    case 'SET_FILTERS':
      return buildFiltersFromParamsObject(state, action.value)
    case 'SET_SELECTED_PROVIDERS':
      return { ...state, ...{ filters: { ...state.filters, selectedProviders: action.value } } }
    case 'SET_SELECTED_GROUPS':
      return { ...state, ...{ filters: { ...state.filters, selectedLocationGroups: action.value } } }
    case 'RESET_FILTERS':
      return { ...state, ...{ filters: { selectedProviders: [], selectedLocationGroups: [] } } }
    case 'SET_CURRENT_PAGE':
      return { ...state, ...{ currentPage: action.value } }
  }
  return state
}

const buildFiltersFromParamsObject = (state, params) => {
  let newFilters = {
    selectedProviders: [],
    selectedLocationGroups: []
  }
  if (params.selectedProviders && params.selectedProviders.length) newFilters.selectedProviders = params.selectedProviders
  if (params.selectedLocationGroups && params.selectedLocationGroups.length) newFilters.selectedLocationGroups = params.selectedLocationGroups
  return { ...state, ...{ filters: newFilters } }
}
